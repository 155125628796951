import Config from "@/config";
import store from "@/store";
import {jointContexts, pickIsSearch} from "@/utils/pick";

export const parseContextIds = (str) => {
    if (!str) return {};
    let rootContextId, contextId;
    if (str.length === Config.AUTH_LENGTH * 2) {
        rootContextId = str.substring(0, Config.AUTH_LENGTH);
        contextId = str.substring(Config.AUTH_LENGTH);
    } else {
        rootContextId = contextId = str;
    }
    return {
        rootContextId,
        contextId,
    }
}

export const rootContext = (context, area) => {
    if (!area) area = store.state.session.area;
    if (!context) return null;
    ///if ( pickIsSearch(context) ) return context;
    let {rootContextId, contextId} = parseContextIds(context.contextId);
    //console.log("rootContextId, contextId", rootContextId, contextId);
    //if ( rootContextId === contextId ) return jointContexts(area).find(el => el.contextId === rootContextId);
    const allContexts = jointContexts(area);
    const c = allContexts.find(el => el.contextId === rootContextId);
    if ( !c ) return null;
    //console.log("C", c);
    if (c.isPick || c.isGroup || c.isUser) return c;
    if (c.isTopic || c.isTag) {
        const g = allContexts.find(el => el.id === c.groupId);
        //console.log("G", g);
        return g;
    }
    return null;
}