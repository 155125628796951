const storage = localStorage;
const session = sessionStorage;

export default {
    get(name) {
        let value = storage.getItem(name);
        if (value) return JSON.parse(value);
        return undefined;
    },

    getSession(name) {
        let value = session.getItem(name);
        if (value) return JSON.parse(value);
        return undefined;
    },

    getNumber(name) {
        let value = storage.getItem(name);
        if (value !== null) return Number(value);
        return undefined;
    },

    getBoolean(name) {
        let value = storage.getItem(name);
        if ( value === "false" ) value = false;
        if (value !== null) return Boolean(value);
        return undefined;
    },

    getSessionNumber(name) {
        let value = session.getItem(name);
        if (value !== null) return Number(value);
        return undefined;
    },

    getString(name) {
        let value = storage.getItem(name);
        if (value !== null) return ""+value;
        return undefined;
    },

    getSessionString(name) {
        let value = session.getItem(name);
        if (value !== null) return ""+value;
        return undefined;
    },

    set(name, value) {
        if ( typeof value === "object" ) storage.setItem(name, JSON.stringify(value));
        else storage.setItem(name, value);
    },

    setSession(name, value) {
        if ( typeof value === "object" ) session.setItem(name, JSON.stringify(value));
        else session.setItem(name, value);
    },

    clear(name) {
        storage.removeItem(name);
    },

    clearSession(name) {
        session.removeItem(name);
    }
};