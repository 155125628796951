<template>
	<v-overlay :absolute="true"
			   :value="hasActor">
		<div v-if="actor" class="d-flex align-center justify-center text-center">
			<v-icon v-if="mutation.icon" class="mr-2">{{ mutation.icon }}</v-icon>
			<v-avatar small :size="avatarSize">
				<v-img v-if="actor.avatar" :src="actor.avatar?.srcThumb"
					   :lazy-src="actor.avatar?.srcLazy"/>
				<v-icon v-else small dark class="grey lighten-2">mdi-account</v-icon>
			</v-avatar>
			<div class="ml-2">{{ actorNick }}</div>
		</div>
	</v-overlay>
</template>

<script>
import Base from "@/mixins/base";
import {nick} from "@/utils/user";

export default {
	name: "mutation",
	props: {
		mutation: {},
		subject: {},
		avatarSize: {
			//type: Number,
			default: 32,
		},
	},
	mixins: [Base],
	data: () => ({}),
	computed: {
		hasActor: {
			get() {
				return !!this.actor && this.mutation.subjectAuth === this.subject.auth;
			},
			set(v) {
				return;
			}
		},
		actor() {
			//console.log("mutation", this.mutation);
			const userAuth = this.mutation.userAuth;
			return this.users.find(el => el.auth === userAuth);
		},
		actorNick() {
			return nick(this.actor);
		},
	},
}
</script>

<style lang="scss">

</style>