<template>
	<v-avatar v-if="item" :size="size" class="logo"
			  :rounded="rounded"
			  :class="componentClass" color="orange lighten-4" :title="title">
		<v-img v-if="item.image" :src="item.image?.srcThumb" :lazy-src="item.image?.srcLazy" />
		<v-img v-else-if="item.isPersonal" src="@/assets/1task-logo.png" />
		<span v-else class="logo__fi">{{fi}}</span>
	</v-avatar>
</template>

<script>
import * as userUtils from "@/utils/user";

export default {
	name: "avatar",
	props: {
		item: {},
		small: {
			type: Boolean,
			default: false,
		},
		large: {
			type: Boolean,
			default: false
		},
	},
	data: () => ({}),
	computed: {
		rounded() {
			return !!this.item.withTopics;
		},
		size() {
			if ( this.large ) return 32;
			if ( this.small ) return 22;
			return 24;
		},
		fi() {
			return userUtils.fi(this.item);
		},
		title() {
			return this.name;
		},
		componentClass() {
			const c = [];
			if ( this.small ) c.push("--small");
			return c?.length > 0 ? c : null;
		}
	}
}
</script>

<style lang="scss">
.logo {
	/*width: 28px;
	height: 28px;*/
	overflow: hidden;
	text-transform: uppercase;

	/*&.--small {
		width: 20px;
		height: 20px;
	}*/

	&__fi {
		font-size: .55rem;
	}
}
</style>