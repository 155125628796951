import store from "@/store";

export default [
    {
        path: '/',
        component: () => import('../components/layouts/layout-default'),
        children: [
            {
                path: '/',
                component: () => import('../components/context'),
                children: [
                    {
                        name: 'home',
                        path: '/',
                        component: () => import('../components/context/context-start'),
                        props: true,
                        meta: {
                            /*actions: [
                                {action: "user/tasks", subscribe: ["user"]},
                            ],*/
                            title: () => store.state.page.task.TITLE
                        },
                    },
                    {
                        name: 'context',
                        path: '/x/:contextId',
                        component: () => import('../components/context/context-content'),
                        props: true,
                        meta: {
                            actions: [
                                // TODO THIS IS UNSECURE TO SUBSCRIBE WITHOUT CHECKING VALID ACCESS !!!!!!!!!!
                                {action: "context/item", subscribe: ["context"], isContext: true, withErrorHandler: true},
                            ],
                            title: () => store.state.page.context.TITLE
                        },
                        children: [
                            {
                                name: 'context-done',
                                path: '/x/:contextId/done',
                                component: () => import('../components/context/context-content-task'),
                                props: true,
                                meta: {
                                    actions: [
                                        // TODO THIS IS UNSECURE TO SUBSCRIBE WITHOUT CHECKING VALID ACCESS !!!!!!!!!!
                                        {action: "context/item", subscribe: ["context"], isContext: true},
                                    ],
                                    title: () => "Все сделано!"
                                }
                            },
                            {
                                name: 'context-task',
                                path: '/x/:contextId/:taskAuth',
                                component: () => import('../components/context/context-content-task'),
                                props: true,
                                meta: {
                                    actions: [
                                        // TODO THIS IS UNSECURE TO SUBSCRIBE WITHOUT CHECKING VALID ACCESS !!!!!!!!!!
                                        {action: "context/item", subscribe: ["context"], isContext: true, withErrorHandler: true},
                                        {action: "task/item", subscribe: ["task"], withAnon: true},
                                    ],
                                    title: () => store.state.page.task.TITLE
                                }
                            },
                        ]
                    },
                    /*{
                        path: '/s',
                        name: 'search',
                        component: () => import('../components/context/context-content'),
                        meta: {
                            actions: [
                                {action: "user/search", query: {search: "s"}}
                            ],
                            title: () => "Результаты поиска " + store.state.ui.search.value
                        },
                        children: [
                            {
                                name: 'search-task',
                                path: '/s/:taskAuth',
                                component: () => import('../components/context/context-content-task'),
                                props: true,
                                meta: {
                                    actions: [
                                        {action: "user/search", query: {search: "s"}},
                                        {action: "task/item", subscribe: ["task"], withAnon: true},
                                    ],
                                    title: () => store.state.page.task.TITLE
                                }
                            },
                        ]
                    },*/
                ]
            },
            {
                path: '/1',
                component: () => import('../components/context/context-content'),
                children: [
                    {
                        name: 'home-done',
                        path: '/1/done',
                        component: () => import('../components/context/context-content-task'),
                        props: true,
                        meta: {
                            actions: [
                                {action: "user/tasks", subscribe: ["user"]},
                            ],
                            title: () => "Все сделано!"
                        }
                    },
                    {
                        name: 'home-task',
                        path: '/1/:taskAuth',
                        component: () => import('../components/context/context-content-task'),
                        props: true,
                        meta: {
                            actions: [
                                {action: "user/tasks", subscribe: ["user"], isContext: true},
                                {action: "task/item", subscribe: ["task"], withAnon: true},
                            ],
                            title: () => store.state.page.task.TITLE
                        }
                    },
                ]
            },
        ]
    },

    {
        path: '/_/auth',
        name: 'auth',
        component: () => import('../components/auth'),
        meta: {
            skipSession: true,
        }
    },
    {
        path: '/_/auth-as',
        name: 'auth-as',
        component: () => import('../components/auth-as'),
        meta: {
            skipSession: true,
        }
    },
    {
        path: '/_/auth-tg',
        name: 'auth-tg',
        component: () => import('../components/auth-tg'),
        meta: {
            skipSession: true,
        }
    },
    {
        path: '/_/logout',
        name: 'logout',
        component: () => import('../components/logout'),
        meta: {
            skipSession: true,
        }
    },
    {
        path: '/error/404',
        name: 'error404',
        component: () => import('../components/exception'),
        meta: {code: '404'}
    },
    {
        path: '*',
        name: 'error-unknown',
        component: () => import('../components/exception'),
        props: true,
    },
];
