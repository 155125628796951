import Vue from "vue";
import {sortByAuth} from "@/utils/group";
import {jointContexts, obtainPickSingleParent, pickSingleIntValue, pickSingleValue, PRESETS} from "@/utils/pick";
import * as userUtils from "@/utils/user";

export const initGroupsInArea = (area) => {
    if (area.topics?.length) {
        area.groups?.forEach(group => {
            const topics = area.topics?.filter(topic => topic.groupId === group.id);
            sortByAuth(topics, group.topicSort);
            const tags = area.tags?.filter(tag => tag.groupId === group.id && !tag.topicId && tag.withActiveTasks);
            const topicsAndTags = [...(topics || []), ...(tags || [])];
            //if ( group.auth === "x14_0tmn47ZXTbxL" ) console.log("CHILDREN", topicsAndTags);
            Vue.set(group, "children", topicsAndTags);
            topicsAndTags?.forEach(el => Vue.set(el, "parent", group));
            Vue.set(group, "withArchivedTopics", true);
            //console.log("TOPICS of "+group.name, topics.map(el=>el.auth+":"+el._isArchived));
        });
        area.topics?.forEach(topic => {
            const tags = area.tags?.filter(tag => tag.topicId === topic.id && tag.withActiveTasks);
            if (tags?.length) {
                sortByAuth(tags, topic.tagSort, "id");
                Vue.set(topic, "children", tags);
                tags.forEach(el => Vue.set(el, "parent", topic));
                //console.log("TAGS of "+topic.name, tags.map(el=>el.id+":"+el.name));
            }
        });
        //console.log("TAGS", area.tags);
        //console.log("TAGS of "+group.name, topics.map(el=>el.auth+":"+el._isArchived));
    }
}

export const initPicksInArea = (area) => {
    if (!area.picks) Vue.set(area, "picks", []);

    const contexts = jointContexts(area);
    area.picks?.forEach(pick => {
        //const user = state.session.user;
        //sortByAuth(area.picks, user.favUserSort, "contextId");

        // 1. add to picks some props of base contexts
        const contextId = pickSingleValue(pick, "contextId");
        const context = contexts.find(el => el.contextId === contextId);
        if (context?.isGroup) {
            if (context.image) Vue.set(pick, "image", context.image);
            Vue.set(pick, "shouldBeWithLogo", true);
            Vue.set(pick, "withTopics", context.withTopics);
            Vue.set(pick, "isPersonal", context.isPersonal);
        } else if (context?.isTopic) {
            if (context.emoji) Vue.set(pick, "emoji", context.emoji);
        } else if (context?.isUser) {
            if (context.avatar) Vue.set(pick, "avatar", context.avatar);
            Vue.set(pick, "shouldBeWithAvatar", true);
        } else {
            // finally check pick for assignee
            const userId = pickSingleIntValue(pick, "userId");
            if (userId) {
                const user = area.users.find(el => el.id === userId);
                if (user) {
                    if (user.avatar) Vue.set(pick, "avatar", user.avatar);
                    else Vue.set(pick, "avatarName", userUtils.fi(user));
                    //Vue.set(pick, "shouldBeWithAvatar", true);
                }
            }
        }

        /*const groupId = pickSingleIntValue(pick, "groupId");
        if (groupId) {
            console.log("PICK groupId: " + groupId, pick.contextId);
            const group = area.groups.find(el => el.id === groupId);
            if (group) {
                if (group.image) Vue.set(pick, "image", group.image);
                Vue.set(pick, "shouldBeWithLogo", true);
                Vue.set(pick, "withTopics", group.withTopics);
                Vue.set(pick, "isPersonal", group.isPersonal);
            }
        } else {
            const userId = pickSingleIntValue(pick, "userId");
            if (userId) {
                const user = area.users.find(el => el.id === userId);
                if (user) {
                    if (user.avatar) Vue.set(pick, "avatar", user.avatar);
                    Vue.set(pick, "shouldBeWithAvatar", true);
                }
            }
        }*/

        // 2. leave in children only the contexts that exist in area
        const keepOnlyValidChildren = (context) => {
            if (!context.children?.length) return;
            context.children = context.children.filter(child => {
                if (child.isGroup) {
                    return !!area.groups.find(el => el.id === child.id);
                } else if (child.isTopic) {
                    return !!area.topics.find(el => el.id === child.id);
                } else if (child.isTag) {
                    return !!area.tags.find(el => el.id === child.id);
                }
                return true;
            });
            context.children?.forEach(child => keepOnlyValidChildren(child));
        }
        keepOnlyValidChildren(pick);

        /*if ( pick.conds?.length === 1 && pick.conds[0].length === 1 && pick.conds[0][0].key === "tagId" ) {
            //Vue.set(list, "isTag", true);
            pick.name = "#" + pick.name;
            return;
        }
        else if ( pick.conds?.length === 1 && pick.conds[0].length === 1 && pick.conds[0][0].key === "topicId" ) {
            //Vue.set(list, "isTag", true);
            //list.name = "#" + list.name;
            return;
        }

        let groupContexts = area.contexts?.filter(context => !!context.groupId && context.parentId === pick.id);
        //console.log("contexts", area.contexts);
        //console.log("groupContexts", groupContexts);
        if (groupContexts?.length === 1) {

            let groupContext = groupContexts[0];
            const group = area.groups.find(el => el.id === groupContext.groupId);
            //groupContext.name = group.name;

            const topicContexts = group.children.map(topic => {
                const topicContext = area.contexts.find(el => el.topicId === topic.id);
                if (!topicContext) return null;
                Vue.set(topicContext, "isTopic", true);
                //topicContext.name = topic.name;
                return topicContext;
            }).filter(el => !!el);
            //Vue.set(list, "isGroup", true);
            //Vue.set(groupContext, "children", topicContexts);
            //topicContexts?.forEach(el => Vue.set(el, "parent", groupContext));
            if (topicContexts?.length > 1) {
                Vue.set(pick, "children", topicContexts);
                topicContexts?.forEach(el => Vue.set(el, "parent", pick));
            }
        } else {
            groupContexts?.forEach(groupContext => {
                const group = area.groups.find(el => el.id === groupContext.groupId);
                //groupContext.name = group.name;

                const topicContexts = group.children.map(topic => {
                    const topicContext = area.contexts.find(el => el.topicId === topic.id);
                    if (!topicContext) return null;
                    Vue.set(topicContext, "isTopic", true);
                    //topicContext.name = topic.name;
                    return topicContext;
                }).filter(el => !!el);
                Vue.set(groupContext, "isGroup", true);
                Vue.set(groupContext, "children", topicContexts);
                topicContexts?.forEach(el => Vue.set(el, "parent", groupContext));
            });
            Vue.set(pick, "children", groupContexts);
            groupContexts?.forEach(el => Vue.set(el, "parent", pick));
        }*/
    });

    //console.log("AREA PICKS INITED", area.picks);
};

export const initUserPicksInArea = (area) => {
    if (!area.userPicks) Vue.set(area, "userPicks", []);

    // add avatars to user picks
    if (area.userPicks.length) {
        //const user = state.session.user;
        //console.log("user.favUserSort", user.favUserSort, typeof user.favUserSort);
        //sortByAuth(area.userPicks, user.favUserSort, "contextId");
        area.userPicks?.forEach(userContext => {
            //userContext.isUser = true;
            //const user = area.users.find(el => el.auth === userContext.auth);
            //if (user) userContext.avatar = user.avatar;
            //console.log("userContext.avatar", userContext.avatar);
        });
    }

    //console.log("AREA USER PICKS INITED", area.userPicks);
};

export const sortAllContextsChildren = (area, user) => {
    area.picks?.forEach(context => {
        sortContextChildren(context, area, user);
        context.children?.forEach(child => {
            sortContextChildren(child, area, user);
            child.children?.forEach(child => {
                sortContextChildren(child, area, user);
                child.children?.forEach(child => {
                    sortContextChildren(child, area, user);
                });
            });
        });
    });
    area.userPicks?.forEach(context => {
        sortContextChildren(context, area, user);
        context.children?.forEach(child => {
            sortContextChildren(child, area, user);
            child.children?.forEach(child => {
                sortContextChildren(child, area, user);
                child.children?.forEach(child => {
                    sortContextChildren(child, area, user);
                });
            });
        });
    });
}

export const sortContextChildren = (context, area, user) => {
    if (!context) return;
    const children = context.children;
    if (!children?.length) return;
    const firstChild = children[0];
    if (firstChild.isGroup) {
        sortByAuth(children, user.groupSort);
    } else if (firstChild.isTopic) {
        const group = area.groups?.find(el => el.id === firstChild.groupId);
        if (group) sortByAuth(children, group.topicSort);
    } else if (firstChild.isTag) {
        const topic = area.topics?.find(el => el.id === firstChild.topicId);
        console.log("SORTING TAGS WITH ", topic?.tagSort);
        if (topic) sortByAuth(children, topic.tagSort);
    }
}
