import Config from '@/config';

const actions = {
    /**
     * Fetches group tasks.
     * @param store
     * @param params
     * @returns {*}
     */
    /*tasks(store, params) {
        return store.dispatch('get', {
            action: 'topic/tasks',
            params
        }, {root: true});
    },*/

    assign(store, params) {
        console.log("ACTION topic/assign", params);

        // local change
        store.commit("topicAssign", params, {root: true});

        // remote change
        return store.dispatch('post', {
            action: 'topic/assign',
            params
        }, {root: true});
    },

    delete(store, params) {
        console.log("ACTION topic/delete", params);

        // local change
        store.commit("topicDelete", params, {root: true});

        // remote change
        return store.dispatch('post', {
            action: 'topic/delete',
            params
        }, {root: true});
    },

    /**
     * Displays topic selector.
     * @param store
     * @param params
     * @returns {Promise<unknown>}
     */
    selector(store, params) {
        return new Promise((resolve, reject) => {
            params.callback = (res) => resolve(res);
            store.commit('uiMenu', {
                menu: store.rootState.ui.topicSelector,
                params
            }, {root: true});
        });
    },
};

export default actions;
