import Vue from 'vue';
import Config from '@/config';
import store from '@/store';
import router from '@/router';
import uiMutations from "@/store/ui/mutations";
import {findPs} from "@/utils/p";
import {pickSingleValue, pickSingleIntValue} from "@/utils/pick";
import {PRIOS, prios, TABS} from "@/utils/task";
import {parseContextIds} from "@/utils/context";
import {HocuspocusProvider} from "@hocuspocus/provider";
import * as Y from 'yjs';

const mutations = {
    taskItem: (state, payload) => {
        console.log("MUTATION task/item", payload);

        // TODO MOVE TO uiValidateRoute
        // validate route for anon
        if (state.session.isAnon) {
            if (router.currentRoute.name === "my-task") {
                if (state.page.task.topic) {
                    router.replace({
                        name: "topic-task",
                        params: {
                            taskAuth: state.page.task.item.auth,
                            topicAuth: state.page.task.topic.auth,
                        }
                    });
                } else {
                    router.replace({
                        name: "topic-task",
                        params: {
                            taskAuth: state.page.task.item.auth,
                            groupAuth: state.page.task.group.auth,
                        }
                    });
                }
            }
        }
    },
    taskCreate: (state, payload) => {
        console.log("MUTATION task/create", payload);

        // after creation - call this
        payload.mutation = "taskAppend";

        // after creation - broadcast to subscribers of this
        const broadcast = [{
            entity: "group",
            auth: payload.groupAuth,
        }];
        if (payload.topicAuth) broadcast.push({
            entity: "topic",
            auth: payload.topicAuth,
        });
        payload.broadcast = broadcast;
        console.log("broadcast", broadcast);
    },
    taskAppend: (state, payload) => {
        console.log("MUTATION task/append", payload);
        if (!payload?.task?.item) return;

        const item = payload?.task?.item;
        const groupId = item.groupId;
        const topicId = item.topicId;
        const tagIds = item.tagIds;

        //console.log("groupId", groupId, topicId);

        // is context the same?
        let group, topic, tag;
        const area = state.session.area;
        const context = state.page.context.item;
        if (!context) return;
        const contextId = context.contextId;

        if (context.isGroup) group = area.groups?.find(el => el.contextId === contextId);
        else if (context.isTopic) topic = area.topics?.find(el => el.contextId === contextId);
        else if (context.isTag) tag = area.tags?.find(el => tagIds.includes(el.contextId));
        else if (context.isPick) {
            const groupId = pickSingleIntValue(context, "groupId");
            if (groupId) group = area.groups?.find(el => el.id === groupId);
            else {
                const topicId = +pickSingleIntValue(context, "topicId");
                if (topicId) topic = area.topics?.find(el => el.id === topicId);
                else {
                    const tagId = pickSingleValue(context, "tagId");
                    if (tagId) group = area.tags?.find(el => el.id === tagId);
                }
                /*else {
                    const userId = +pickSingleValue(context, "userId");
                    if (userId) group = area.groups?.find(el=>el.id === groupId);
                }*/
            }
        }

        if (
            // todo with contextId
            //contextId === state.page.context.item?.contextId
            // group/topic
            (topic && topicId === topic.id)
            || (!topic && group && groupId === groupId)
            || tag
        ) {

            // check tab is appropriate
            if (
                ((item.isActive) && (state.page.context.tab === TABS.ACTUAL || !state.page.context.tab))
                || ((item.isDelayed || item.delayedTill) && state.page.context.tab === TABS.DELAYED)
                || ((item.doneAt || item.isDone) && state.page.context.tab === TABS.DONE)
                || (item.isTemplate && state.page.context.tab === TABS.TEMPLATES)
            ) {
                let items = state.page.tasks.items;
                if (!items) {
                    Vue.set(state.page.tasks, "items", []);
                    items = state.page.tasks.items;
                }

                // insert task into context
                if (items.findIndex(el => el.auth === item.auth) === -1) {
                    //items.unshift(item);
                    //items.push(item);

                    if (item.isActive) {
                        // append new active task after last active task
                        let index = [...items].reverse().findIndex(el => el.isActive);
                        index = items.length - index;
                        //console.log("FOUND LAST ACTIVE " + index);
                        //index++;    // we append after it!
                        if (index >= items.length) items.push(item);
                        else items.splice(index, 0, item);
                    } else {
                        // append new inactive task to the end
                        items.push(item);
                    }
                }
            }
        }

        // update current item
        //if (state.page.task.item && state.page.task.item.auth === auth) update(state.page.task.item);
    },
    taskUpdate: (state, payload) => {
        console.log("MUTATION task/update", payload);
        if (!payload?.taskAuth) return;
        if (!payload?.params) return;

        const auth = payload.taskAuth;

        // check if first paragraph is "done" - we should add isDone to the task
        /*if (payload.params.name) {
            const ps = findPs(payload.params.name);
            if (ps?.length) {
                //console.log("PS", ps);
                if (ps[0].match(/<p[^>]+--done[^>]+>/)) {
                    payload.params.isDone = true;
                } else {
                    payload.params.isDone = false;
                    payload.params.doneAt = null;
                }
            }
        }*/

        const update = (item) => {
            /*if ( payload.prio === PRIOS.NOTE ) {
                payload.isNote = true;
                payload.deadline = null;
                payload.isDelayed = null;
                payload.delayedTill = null;
            }*/
            Object.keys(payload?.params).forEach(key => {
                Vue.set(item, key, payload?.params[key]);
            });
        }

        // update item in a context
        const items = state.page.tasks.items;
        const lists = [state.page.tasks.items, state.page.tasks.soonItems];
        let item;
        lists.forEach(items => {
            if (item) return;
            if (items?.length) {
                item = items.find(el => el.auth === auth);
                if (item) update(item);
            }
        });

        // update current item
        if (state.page.task.item?.auth === auth) {
            update(state.page.task.item);

            // apply msgs
            if (payload.msgs?.items) {
                state.page.msgs.items = payload.msgs.items;
            }
        }

        // todo update parent group and topic to set activityAt
        //const group = state.session.area.groups.find(el=>el.id===item

        // broadcast to subscribers of this
        if (!state.session.isAnon) {
            item = item || state.page.task.item;
            if (item) {
                payload.broadcasts = _broadcastsForTask(state, item);
                console.log("broadcast", payload.broadcasts);

                uiMutations.uiSetActorOfTask(state, {
                    userAuth: payload.userAuth,
                    subjectAuth: item.auth,
                    icon: "mdi-pencil-outline",
                });
            }
        }
    },
    taskDelete: (state, payload) => {
        console.log("MUTATION task/delete", payload);

        const auth = payload.taskAuth;
        if (state.page.deletedTaskAuths.find(el => el === auth)) throw "Should not delete the task twice";
        state.page.deletedTaskAuths.push(auth);

        const items = state.page.tasks.items;
        let item;
        if (items?.length) {
            const index = items.findIndex(el => el.auth === auth);
            if (index >= 0) {
                item = items[index];

                const action = () => {
                    items.splice(index, 1);
                };

                if (payload.initiatorAuth) {
                    uiMutations.uiSetActorOfTask(state, {
                        userAuth: payload.userAuth,
                        subjectAuth: item.auth,
                        icon: "mdi-close",
                    });
                    setTimeout(action, 600);
                } else action();
            }
        }

        // todo remove current edited task

        if (item) {
            //console.log("ITEM", item);
            const group = state.session.area.groups.find(el => el.id === item.groupId);
            if (group) {
                //console.log("GROUP", group);
                let topic = item.topicId ? state.session.area.topics.find(el => el.id === item.topicId) : null
                //console.log("TOPIC", topic);
                const broadcast = [{
                    entity: "group",
                    auth: group.auth,
                }];
                if (topic) broadcast.push({
                    entity: "topic",
                    auth: topic.auth,
                });
                payload.broadcast = broadcast;
                console.log("broadcast", broadcast);
            }
        }
    },
    taskAssign: (state, payload) => {
        console.log("MUTATION task/assign", payload);
        if (!payload?.taskAuth) return;
        //if (!payload?.assigneeAuth&&!payload?.ownerAuth) return;

        const auth = payload.taskAuth;
        const userAuth = payload.assigneeAuth || payload.ownerAuth;

        let userId = null;
        if (userAuth) {
            const user = state.session.area.users.find(el => el.auth === userAuth);
            //console.log("USER FOUND", user);
            if (user) userId = user.id;
            else return;
        }

        let property;
        if (typeof payload.ownerAuth !== "undefined") property = "ownerId";
        else property = "userId";

        // update item in a context
        const items = state.page.tasks.items;
        let item;
        if (items?.length) {
            item = items.find(el => el.auth === auth);
            if (item) item[property] = userId;
        }

        // update current item
        if (state.page.task.item && state.page.task.item.auth === auth) state.page.task.item[property] = userId;

        // broadcast to subscribers of this
        item = item || state.page.task.item;
        if (item) {
            payload.broadcasts = _broadcastsForTask(state, item);
            console.log("broadcast", payload.broadcasts);

            if (payload.initiatorAuth) {
                uiMutations.uiSetActorOfTask(state, {
                    userAuth: payload.userAuth,
                    subjectAuth: item.auth,
                    icon: "mdi-at",
                });
            }
        } else {
            // is this a new task?
        }
    },
    taskCompanion: (state, payload) => {
        console.log("MUTATION task/companion", payload);
        if (!payload?.taskAuth) return;
        //if (!payload?.assigneeAuth&&!payload?.ownerAuth) return;

        const auth = payload.taskAuth;
        const userAuth = payload.userAuth;
        const companionAuth = payload.companionAuth;

        // we can only add a user we know
        if (userAuth) {
            const user = state.session.area.users.find(el => el.auth === userAuth);
            if (!user) return;
            //console.log("USER FOUND", user);
        }

        const pushCompanion = (task, userAuth) => {
            let companionAuths = task.companions || [];
            if (typeof companionAuths === "string") companionAuths = JSON.parse(companionAuths);

            if (companionAuth) {
                const index = companionAuths.findIndex(el => el === companionAuth);
                if (index >= 0) {
                    if (userAuth) companionAuths.splice(index, 1, userAuth);
                    else companionAuths.splice(index, 1);
                }
            } else if (userAuth && !companionAuths.includes(userAuth)) companionAuths.push(userAuth);

            Vue.set(task, "companions", companionAuths);
        }

        // update item in a context
        const items = state.page.tasks.items;
        let item;
        if (items?.length) {
            item = items.find(el => el.auth === auth);
            if (item) {
                //console.log("Item in list found", item);
                pushCompanion(item, userAuth);
            }
        }

        // update current item
        if (state.page.task.item && state.page.task.item.auth === auth) {
            console.log("Current item found", state.page.task.item);
            pushCompanion(state.page.task.item, userAuth);
        }

        // broadcast to subscribers of this
        item = item || state.page.task.item;
        if (item) {
            //payload.mutation = "task/update";
            /*payload.params = {
                taskAuth: item.auth,
                params: {
                    companions: item.companions,
                }
            }*/
            payload.broadcasts = _broadcastsForTask(state, item);
            //console.log("broadcast", payload.broadcasts);

            if (payload.initiatorAuth) {
                uiMutations.uiSetActorOfTask(state, {
                    userAuth: payload.userAuth,
                    subjectAuth: item.auth,
                    icon: "mdi-at",
                });
            }
        } else {
            // is this a new task?
        }
    },
    taskTemplate: (state, payload) => {
        console.log("MUTATION task/template", payload);
        mutations.taskUpdate(state, payload);
    },
    /**
     * A task moved to a context (eg. as a result of DND over context).
     * @param state
     * @param payload
     */
    taskContext: (state, payload) => {
        console.log("MUTATION task/context", payload);
        if (!payload.taskAuth) return;

        const taskAuth = payload.taskAuth;
        const targetContextId = payload.contextId;
        const items = state.page.tasks.items;
        const currentContext = state.page.context.item;
        const area = state.session.area;
        //console.log("items", items);

        const {rootContextId, contextId} = parseContextIds(targetContextId);
        const contexts = [
            ...area.picks,
            ...area.userPicks,
            ...area.groups,
            ...area.topics,
            ...area.tags,
        ];
        const newContext = contexts.find(el => el.contextId === contextId);
        if (!newContext) return;

        //console.log("newContext", newContext);

        const proxyToTaskAssign = (assigneeAuth) => {
            // we go to task/assign
            mutations.taskAssign(state, {
                taskAuth,
                assigneeAuth
            });
        }
        const proxyToTaskTag = (tagId) => {
            // we go to task/tag
            payload.tagId = tagId;
            payload.mutation = "taskTag";
            mutations.taskTag(state, payload);
        }

        let newGroup, newTopic, newUser;
        if (newContext.isGroup) newGroup = newContext;
        else if (newContext.isTopic) {
            newTopic = newContext;
            newGroup = area.groups.find(el => el.id === newTopic.groupId);
        } else if (newContext.isPick) {
            const groupId = pickSingleValue(newContext, "groupId");
            if (groupId) newGroup = newContext;
            else {
                const topicId = pickSingleValue(newContext, "topicId");
                if (topicId) newTopic = newContext;
                else {
                    const userId = pickSingleValue(newContext, "userId");
                    if (userId) {
                        const user = area.users.find(el => el.id === userId);
                        return proxyToTaskAssign(user.auth);
                    }
                }
            }
        } else if (newContext.isUser) return proxyToTaskAssign(newContext.contextId);
        else if (newContext.isTag) return proxyToTaskTag(newContext.id);

        // 1. remove from current context
        let task;
        if (items?.length) {
            const index = items.findIndex(el => el.auth === taskAuth);
            if (index >= 0) {
                task = items[index];

                const oldGroup = area.groups.find(el => el.id === task.groupId);
                let oldTopic;
                if (task.topicId) oldTopic = area.topics.find(el => el.id === task.topicId);

                console.group("Moving task: " + task.auth);
                console.log("Current context:" + currentContext.name);
                console.log("Current context isGroup:" + currentContext.isGroup);
                console.log("Current context isTopic:" + currentContext.isTopic);
                console.log("Current context isTag:" + currentContext.isTag);
                console.log("Current context isPick:" + currentContext.isPick);
                console.log("Current context isUser:" + currentContext.isUser);
                console.log("Old group: " + oldGroup.name);
                if (oldTopic) console.log("Old topic: " + oldTopic.name);
                if (newGroup) console.log("New group: " + newGroup.name);
                else if (newTopic) console.log("New topic: " + newTopic.name);
                console.groupEnd();

                //console.log("route", router.currentRoute.name);
                const currentRoute = router.currentRoute.name;

                if (currentRoute === "context" || currentRoute === "context-task") {

                    // TODO rewrite with smth like isTaskSatisfyingToContextConds(task, context);

                    if ((currentContext.isTopic && newTopic && newTopic.id !== currentContext?.id)
                        || (currentContext.isTopic && !newTopic)
                        || (currentContext.isGroup && newGroup && newGroup.id !== oldGroup.id)
                        || (currentContext.isPick && newGroup && newGroup.id !== oldGroup.id)   // this is not enough and can be a bug! // TODO rewrite with smth like isTaskSatisfyingToContextConds(task, context);
                    ) {
                        console.log("REMOVING ITEM", task);

                        const action = () => {
                            items.splice(index, 1);
                        };

                        if (payload.initiatorAuth) {
                            uiMutations.uiSetActorOfTask(state, {
                                userAuth: payload.userAuth,
                                subjectAuth: task.auth,
                                icon: "mdi-close",
                            });
                            setTimeout(action, 600);
                        } else action();
                        //items.splice(index, 1);
                    } else {
                        // skip removing from same group/topic
                        console.log("Skipped removing from same group/topic");
                    }
                }
            }
        }

        if (task) {

            // 1. apply task changes
            if (newContext.isGroup || newContext.isTopic) {
                task.groupId = newGroup.id;
                task.topicId = newTopic?.id;
            }

            // 2. broadcast
            const broadcasts = [];
            const group = state.session.area.groups.find(el => el.id === task.groupId);
            if (group) {
                broadcasts.push({
                    entity: "group",
                    auth: group.auth,
                });
                const topic = task.topicId ? state.session.area.topics.find(el => el.id === task.topicId) : null;
                if (topic) broadcasts.push({
                    entity: "topic",
                    auth: topic.auth,
                });
            }
            if (newGroup) {
                broadcasts.push({
                    entity: "group",
                    auth: newGroup.auth,
                });
            }
            if (newTopic) broadcasts.push({
                entity: "topic",
                auth: newTopic.auth,
            });

            if (broadcasts.length) payload.broadcasts = broadcasts;
            console.log("broadcasts", broadcasts);

            uiMutations.uiSetActorOfGroup(state, {
                userAuth: payload.userAuth,
                subjectAuth: newTopic?.auth || newGroup?.auth,
                icon: "mdi-flare",
            });
        }

        // 2. append current tasks
        if (payload.newTask) {
            const group = state.page.context.group;
            const topic = state.page.context.topic;
            if ((payload.newTask.topicId && topic && payload.newTask.topicId === topic.id)
                || (payload.newTask.groupId && !topic && group && payload.newTask.groupId === group.id)) {
                //console.log("NEW TASK", payload.newTask);
                mutations.taskAppend(state, {
                    task: {
                        item: payload.newTask
                    }
                });

                uiMutations.uiSetActorOfTask(state, {
                    userAuth: payload.userAuth,
                    subjectAuth: payload.newTask.auth,
                    icon: "mdi-flare",
                });
            }
        }
    },
    /**
     * Changes task sorting between two tasks in the specified context.
     * @param state
     * @param payload
     */
    taskSort: (state, payload) => {
        console.log("MUTATION task/context", payload);

        const sourceAuth = payload.sourceAuth;
        const destinationAuth = payload.destinationAuth;
        const contextId = payload.contextId;
        const context = state.page.context.item;
        const items = state.page.tasks.items;

        if (items?.length) {
            //console.log("items", items);
            const sourceIndex = items.findIndex(el => el.auth === sourceAuth);
            const destinationIndex = items.findIndex(el => el.auth === destinationAuth);
            if (sourceIndex !== -1 && destinationIndex !== -1) {
                const source = items[sourceIndex];
                items.splice(sourceIndex, 1);
                items.splice(destinationIndex, 0, source);
            }
        }

        if (context?.contextId === contextId) {
            let taskSort;
            if (context.isGroup) taskSort = context.taskSort;
            else if (context.isTag || context.isTopic) {
                // todo obtain parent group
            }
            if (taskSort) {
                //console.log("taskSort", taskSort);
            }
        }
    },
    /**
     * Applies a tag to a task.
     * Updates task text.
     * @param state
     * @param payload
     */
    taskTag: (state, payload) => {
        console.log("MUTATION task/tag", payload);
        if (!payload?.appendTags) return;
        if (!payload?.appendTags.taskAuth) return;
        //if (!payload?.updateTags.tags) return;
        //if (!payload?.assigneeAuth&&!payload?.ownerAuth) return;

        const taskAuth = payload.appendTags.taskAuth;
        const tags = payload.appendTags.tags;
        if (!tags?.length) return;

        const area = state.session.area;
        tags.forEach(tag => {
            if (!area.tags.find(el => el.id === tag.id)) area.tags.push(tag);
        });
        console.log("TASK TAGS", tags);

        const updateTask = (task) => {
            task.tagIds = task.tagIds || [];
            tags.forEach(tag => {
                if (!task.tagIds.includes(tag.id)) task.tagIds.push(tag.id);
            });
        }

        // update item in a context
        const items = state.page.tasks.items;
        let item;
        if (items?.length) {
            item = items.find(el => el.auth === taskAuth);
            if (item) updateTask(item);
        }
        console.log("taskTag", taskAuth, state.page.task.item);
        if (state.page.task.item && state.page.task.item.auth === taskAuth) {
            updateTask(state.page.task.item);
            if (!item) item = state.page.task.item;
        }

        if (item) {

            //payload.mutation = "taskUpdateTags";

            // update editor doc
            /*const provider = new HocuspocusProvider({
                url: Config.API_SERVER_WS_EDITOR_URI,
                name: taskAuth,
                onConnect() {
                    const doc = provider.document;
                    console.log("Provider connected: "+taskAuth, provider.document);

                    // TODO apply to live editor
                    //Y.applyUpdate(doc, diff2)
                },
            });*/

            // broadcast to subscribers of this
            payload.broadcasts = _broadcastsForTask(state, item);
            console.log("broadcast", payload.broadcasts);

            if (payload.initiatorAuth) {
                uiMutations.uiSetActorOfTask(state, {
                    userAuth: payload.userAuth,
                    subjectAuth: item.auth,
                    icon: "mdi-at",
                });
            }
        }
    },
    taskUntag: (state, payload) => {
        console.log("MUTATION task/untag", payload);
        if (!payload?.taskAuth) return;
        if (!payload?.tagId) return;
        //if (!payload?.assigneeAuth&&!payload?.ownerAuth) return;

        const taskAuth = payload.taskAuth;
        const tagId = payload?.tagId;
        const tag = state.session.area.tags?.find(el => el.id === tagId);
        if (!tag) return;

        //console.log("TAG", tag);

        // update item in a context
        const items = state.page.tasks.items;
        let item;
        if (items?.length) {
            item = items.find(el => el.auth === taskAuth);
            //if (item) updateTask(item);
        }
        if (!item) {
            if (state.page.task.item && state.page.task.item.auth === taskAuth) item = state.page.task.item;
        }

        if (item) {
            const tagIds = item.tagIds || [];
            if (!tagIds.includes(tagId)) return;
            const tagIndex = tagIds.findIndex(el => el === tagId);
            if (tagIndex >= 0) tagIds.splice(tagIndex, 1);

            // update item
            //item.tagIds = tagIds;
            mutations.taskUpdate(state, {
                taskAuth,
                params: {
                    //name: item.name += "<p>#" + tag.name + "</p>",
                    tagIds,
                }
            });

            // update editor doc
            /*const provider = new HocuspocusProvider({
                url: Config.API_SERVER_WS_EDITOR_URI,
                name: taskAuth,
                onConnect() {
                    const doc = provider.document;
                    console.log("Provider connected: "+taskAuth, provider.document);

                    // TODO apply to live editor
                    //Y.applyUpdate(doc, diff2)
                },
            });*/

            // broadcast to subscribers of this
            payload.broadcasts = _broadcastsForTask(state, item);
            console.log("broadcast", payload.broadcasts);

            if (payload.initiatorAuth) {
                uiMutations.uiSetActorOfTask(state, {
                    userAuth: payload.userAuth,
                    subjectAuth: item.auth,
                    icon: "mdi-at",
                });
            }
        }
    },
    taskUpdateTags: (state, payload) => {
        console.log("MUTATION task/updateTags", payload);
        //if (!payload?.taskAuth) return;
        //if (!payload?.tagId) return;
        //if (!payload?.assigneeAuth&&!payload?.ownerAuth) return;

        /*const currentTagIds = item.tagIds || [];
        tagIds.forEach(tagId => {
            const tag = state.session.area.tags?.find(el => el.id === tagId);
            if (!tag) return;
            if (currentTagIds.includes(tagId)) return;
            currentTagIds.push(tagId);
        });

        // update item
        mutations.taskUpdate(state, {
            taskAuth,
            params: {
                //name: item.name += "<p>#" + tag.name + "</p>",
                tagIds: currentTagIds,
            }
        });*/
    },
    taskSearch: (state, payload) => {
        console.log("MUTATION task/search", payload);

        const item = payload?.context?.item;
        if (!item) return;

        // TODO should we use context/append instead of this?

        const area = state.session.area;
        let items = area.picks;

        // insert item to picks
        if (items?.findIndex(el => el.id === item.id) === -1) {
            items.push(item);
            console.log("APPENDED", item, items);
        }
    },
    /*taskNote: (state, payload) => {
        console.log("MUTATION task/note", payload);
        if (!payload?.taskAuth) return;

        const taskAuth = payload.taskAuth;
        const isNote = !!payload.isOn;

        const area = state.session.area;
        const tasks = state.page.tasks.items;

        const updateTask = (task) => {
            task.isNote = isNote;
            task.prio = isNote ? PRIOS.NOTE : PRIOS.NONE;
        }

        // update item in a context
        let item;
        if (tasks?.length) {
            item = tasks.find(el => el.auth === taskAuth);
            if (item) updateTask(item);
        }
        if (state.page.task.item && state.page.task.item.auth === taskAuth) {
            updateTask(state.page.task.item);
            if (!item) item = state.page.task.item;
        }

        if (item) {
            if (payload.initiatorAuth) {
                uiMutations.uiSetActorOfTask(state, {
                    userAuth: payload.userAuth,
                    subjectAuth: item.auth,
                    icon: "mdi-at",
                });
            }
        }
    },*/

    /**
     * @deprecated
     * @param state
     * @param payload
     */
    /*taskGroup: (state, payload) => {
        console.log("MUTATION task/group", payload);
        if (!payload.taskAuth) return;

        const auth = payload.taskAuth;
        const items = state.page.tasks.items;
        //console.log("items", items);

        const newTopic = payload.topicAuth ? state.session.area.topics.find(el => el.auth === payload.topicAuth) : null;
        const newGroup = payload.groupAuth ? state.session.area.groups.find(el => el.auth === payload.groupAuth) : null;

        // 1. remove from current tasks
        let movedItem;
        if (items?.length) {
            const index = items.findIndex(el => el.auth === auth);
            if (index >= 0) {
                movedItem = items[index];

                /!*console.log("GROUP", state.page.context.group?.auth);
                console.log("TOPIC", state.page.context.topic?.auth);
                console.log("NEW TOPIC", newTopic?.auth);
                console.log("NEW GROUP", newGroup?.auth);*!/
                console.log("route", router.currentRoute.name);
                const currentRoute = router.currentRoute.name;

                if ((currentRoute === "my" || currentRoute === "my-task")
                    && state.page.context.assignee?.id === movedItem.userId) {
                    // this is a move from user's list to some group or topic
                    // change group/topic of the item - but not remove

                    if (newTopic) {
                        movedItem.topicId = newTopic?.id;
                        movedItem.groupId = state.session.area.groups.find(el => el.auth === newTopic.groupAuth)?.id;
                    } else if (newGroup) {
                        movedItem.topicId = null;
                        movedItem.groupId = newGroup.id;
                    }
                    console.log("Skipped removing as user's personal task");
                } else if ((currentRoute === "topic" || currentRoute === "topic-task")
                    && newTopic && newTopic.auth === state.page.context.topic?.auth) {
                    // should not move from topic to same topic
                    console.log("Skipped moving to same topic");
                } else if ((currentRoute === "group" || currentRoute === "group-task")
                    && (newTopic && newTopic.groupId === movedItem.groupId || newGroup?.id === movedItem.groupId)
                ) {
                    // this is a move to same group but another topic
                    // change topic of the item - but not remove
                    movedItem.topicId = newTopic?.id;
                    console.log("Topic updated in same group");
                } else {
                    console.log("REMOVING ITEM", movedItem);

                    const action = () => {
                        items.splice(index, 1);
                    };

                    if (payload.initiatorAuth) {
                        uiMutations.uiSetActorOfTask(state, {
                            userAuth: payload.userAuth,
                            subjectAuth: movedItem.auth,
                            icon: "mdi-close",
                        });
                        setTimeout(action, 600);
                    } else action();
                    //items.splice(index, 1);
                }
            }
        }
        if (movedItem) {
            const broadcasts = [];
            const group = state.session.area.groups.find(el => el.id === movedItem.groupId);
            if (group) {
                broadcasts.push({
                    entity: "group",
                    auth: group.auth,
                });
                const topic = movedItem.topicId ? state.session.area.topics.find(el => el.id === movedItem.topicId) : null;
                if (topic) broadcasts.push({
                    entity: "topic",
                    auth: topic.auth,
                });
            }
            if (newGroup) {
                broadcasts.push({
                    entity: "group",
                    auth: newGroup.auth,
                });
            }
            if (newTopic) broadcasts.push({
                entity: "topic",
                auth: newTopic.auth,
            });

            if (broadcasts.length) payload.broadcasts = broadcasts;
            console.log("broadcasts", broadcasts);

            uiMutations.uiSetActorOfGroup(state, {
                userAuth: payload.userAuth,
                subjectAuth: newTopic?.auth || newGroup?.auth,
                icon: "mdi-flare",
            });
        }

        // 2. append current tasks
        if (payload.newTask) {
            const group = state.page.context.group;
            const topic = state.page.context.topic;
            if ((payload.newTask.topicId && topic && payload.newTask.topicId === topic.id)
                || (payload.newTask.groupId && !topic && group && payload.newTask.groupId === group.id)) {
                //console.log("NEW TASK", payload.newTask);
                mutations.taskAppend(state, {
                    task: {
                        item: payload.newTask
                    }
                });

                uiMutations.uiSetActorOfTask(state, {
                    userAuth: payload.userAuth,
                    subjectAuth: payload.newTask.auth,
                    icon: "mdi-flare",
                });
            }
        }
    },*/
    /*taskMsgs: (state, payload) => {
        console.log("MUTATION task/msgs", payload);
        if (!payload?.msg?.item.todoId) return;

        // are we in needed task?
        const taskId = payload.msg.item.todoId;
        //console.log("taskId", taskId);
        //console.log("state.page.task.item.id", state.page.task.item?.id);
        if (state.page.task.item?.id === taskId) {
            state.page.msgs.items = payload.msgs.items;
        }
    },*/

};

const _broadcastsForTask = (state, task) => {
    if (!task) return null;

    const broadcasts = [{
        entity: "task",
        auth: task.auth,
    }];
    //console.log("ITEM", item);
    const group = state.session.area.groups.find(el => el.id === task.groupId);
    if (group) {
        broadcasts.push({
            entity: "group",
            auth: group.auth,
        });

        let topic = task.topicId ? state.session.area.topics.find(el => el.id === task.topicId) : null
        if (topic) broadcasts.push({
            entity: "topic",
            auth: topic.auth,
        });
    }

    // broadcast to user
    if (task.userId) {
        const assignee = state.session.area.users.find(el => el.id === task.userId);
        if (assignee) {
            broadcasts.push({
                entity: "user",
                auth: assignee.auth,
            });
        }
    }
    return broadcasts;
}

export default mutations;
