import Vue from "vue";
import Config from "@/config";
import {sortByAuth} from "@/utils/group";
import {initGroupsInArea, initPicksInArea, initUserPicksInArea} from "@/utils/area";
import {date2Iso, iso2Date} from "@/utils/date";
import {nick} from "@/utils/user";
import {findPs} from "@/utils/p";

const mutations = {
    userSession: (state, payload) => {
        console.log("MUTATION userSession", payload);
        if (!payload) return;

        const area = state.session.area;

        // apply isArchived to groups/topics
        const now = new Date().valueOf();
        const millisecondsPerDay = 1000 * 60 * 60 * 24;
        const applyIsArchived = (el, isTopic) => {
            let isArchived = false;
            const updatedAt = iso2Date(el.updatedAt);
            const archivedAt = iso2Date(el.archivedAt);
            let activityAt = iso2Date(el.activityAt);

            // todo make same logic for group - do not update group on background
            if (isTopic && (!activityAt || updatedAt > activityAt)) activityAt = updatedAt;

            //if (el.id === 58) console.log("Archived? " + el.name + ": " + archivedAt + " <> " + activityAt);
            //console.log("Archived? " + el.id + ": " + archivedAt + " <> " + activityAt);
            if (archivedAt && activityAt && archivedAt > activityAt) isArchived = true;
            else if (archivedAt && !activityAt) isArchived = true;
            else if (activityAt) {
                //if ( el.auth === "xVy2VQbLPV01bs6d" ) console.log("activityAt of "+el.name+": "+activityAt);
                const d = activityAt.valueOf();
                const days = Math.floor((now - d) / millisecondsPerDay);
                isArchived = days >= Config.GROUP_AUTO_ARCHIVED_AFTER_DAYS;
            }
            if (!isArchived) Vue.set(el, "archivedAt", null);
            Vue.set(el, "_isArchived", isArchived);
        };
        area.groups?.forEach(el => applyIsArchived(el));
        area.topics?.forEach(el => applyIsArchived(el, true));

        // init area
        initGroupsInArea(area);
        initPicksInArea(area);
        initUserPicksInArea(area);

        // sort contexts
        const user = payload.user;
        if (user) {
            // todo move decoding to backend
            //if (typeof user.taskSort === "string") user.taskSort = JSON.parse(user.taskSort);
            if (typeof user.groupSort === "string") user.groupSort = JSON.parse(user.groupSort);
            if (typeof user.pickSort === "string") user.pickSort = JSON.parse(user.pickSort);
            if (typeof user.favUserSort === "string") user.favUserSort = JSON.parse(user.favUserSort);

            sortByAuth(area.picks, user.pickSort, "contextId");
            sortByAuth(area.userPicks, user.favUserSort, "contextId");
            sortByAuth(area.groups, user.groupSort);
        }

        state.session.isAnon = user?.auth === Config.ANONYMOUS_USER_AUTH;
        //console.log("USER IS ANON: ", user?.auth === Config.ANONYMOUS_USER_AUTH);

        state.session.isInited = true;
    },

    userTaskSort: (state, payload) => {
        //console.log("MUTATION user/taskSort", payload);

        const user = state.session.user;
        if (!user) return;

        // apply to tasks
        const tasks = state.page.tasks.items;
        sortByAuth(tasks, payload.taskSort);
        //console.log("AFTER sort", group.children.map(c=>c.auth));

        // apply to user
        Vue.set(user, "taskSort", user.taskSort);
        //console.log("user.taskSort", user.taskSort);
    },

    userGroupSort: (state, payload) => {
        console.log("MUTATION user/groupSort", payload);
        const user = state.session.user;
        if (!user) return;

        const groups = state.session.area.groups;
        if (!groups) return;

        sortByAuth(groups, payload.groupSort);
        //console.log("AFTER sort", group.children.map(c=>c.auth));

        user.groupSort = payload.groupSort;
    },

    userPickSort: (state, payload) => {
        console.log("MUTATION user/pickSort", payload);

        const user = state.session.user;
        if (!user) return;

        const items = state.session.area.picks;
        if (!items) return;

        sortByAuth(items, payload.pickSort, "contextId");

        user.pickSort = payload.pickSort;
    },

    userOnline: (state, payload) => {
        console.log("MUTATION userOnline", payload);
        if (!payload) return;
        if (!state.session.area?.users) return;  // session is not yet fetched

        const onlineUsers = state.session.area.onlineUsers;
        const exists = onlineUsers?.find(el => el.auth === payload.userAuth);
        if (!exists) {
            const user = state.session.area.users.find(el => el.auth === payload.userAuth);
            if (user) onlineUsers.push(user);
        }
    },

    userOffline: (state, payload) => {
        console.log("MUTATION userOffline", payload);
        if (!payload) return;
        if (!state.session.area?.users) return;  // session is not yet fetched

        const onlineUsers = state.session.area.onlineUsers;
        const index = onlineUsers?.findIndex(el => el.auth === payload.userAuth);
        if (index >= 0) onlineUsers.splice(index, 1);
    },

    userFav: (state, payload) => {
        console.log("MUTATION userFav", payload);
        if (!payload?.userAuth) return;

        const user = state.session.area.users?.find(el => el.auth === payload.userAuth);
        if (user) {
            Vue.set(user, "isFav", payload.isFav);
            Vue.set(user, "updatedAt", date2Iso(new Date()));

            // add userPick
            if (!state.session.area.userPicks) Vue.set(state.session.area, "userPicks", []);
            state.session.area.userPicks.push({
                ...user,
                contextId: user.auth,
                isUser: true,
            });
        }
    },

    userFavUserSort: (state, payload) => {
        console.log("MUTATION user/favUserSort", payload);

        const user = state.session.user;
        if (!user) return;

        // apply to users
        const users = state.session.area.userPicks;
        if (users?.length) {
            sortByAuth(users, payload.favUserSort, "contextId");
        }

        // apply to user
        Vue.set(user, "favUserSort", user.favUserSort);
    },

    userUser: (state, payload) => {
        console.log("MUTATION user/user", payload);

        if (!payload?.updatedUser?.auth) return;

        const auth = payload.updatedUser.auth;

        const update = (item) => {
            Object.keys(payload.updatedUser).forEach(key => {
                Vue.set(item, key, payload.updatedUser[key]);
            })
        }

        // update current item
        const user = state.session.area.users?.find(el => el.auth === auth);
        //console.log("USER FOUND", user);
        if (user) update(user);
        if (state.page.context.assignee) update(state.page.context.assignee);
    },
};

export default mutations;
