export default {
    YA_COUNTER_NAME: '',	// fixme
    LIVE_HOST: "",	// empty for same host as FE
    API_HOST: "",	// empty for same host as FE
    API_DEFAULT_ACTION: '/api/Form',

    API_WITH_WS: true,
    API_SERVER_WS_URI: 'wss://1ta.sk/ws',
    API_SERVER_WS_PORT: '', // keep empty if nginx proxies to a server port
    API_SERVER_WS_EDITOR_URI: 'wss://1ta.sk/editor',

    DEFAULT_TITLE: "1Task",

    STORAGE_CLIENT_ID_TOKEN: "auth",
    STORAGE_APP_ID: "appId",
    STORAGE_CONFIG: "config",
    STORAGE_CURRENT_GROUP_AUTH: "group",
    STORAGE_GROUP_IDS: "groupIds",
    STORAGE_GROUP_TAB: "tab",
    STORAGE_GROUP_SEARCH: "search",
    STORAGE_WORKSPACE_AUTH: "workspace",
    STORAGE_TAB_ID_TOKEN: "tab",
    STORAGE_USER_LIST_MODE: "user-list-mode",

    WS_CONNECT_CHECK_EVERY_MS: 300,
    WS_CONNECT_TIMEOUT_MS: 12000,
    WS_RESPONSE_CHECK_EVERY_MS: 30,
    WS_RESPONSE_TIMEOUT_MS: 30000,

    WS_ACTION_HELLO: "user/hello",

    API_EXCEPTION_EXISTS: 'EXISTS',
    API_EXCEPTION_CODE_404: 404,
    API_EXCEPTION_CODE_403: 403,

    REFRESH_PAGE_ON_INACTIVITY_AFTER_SECONDS: 15 * 60,

    DEBOUNCE_INPUT_TIMEOUT: 800,
    DEBOUNCE_INPUT_TIMEOUT_SHORT: 400,
    DEBOUNCE_INPUT_TIMEOUT_LONG: 1500,

    COLORS: [
        {color: "#EF5350", bg: "#FFEBEE", fg: "#fff", dark: true, css: "--color1", map: "red lighten-5", icon: "red lighten-1"},
        {color: "#EC407A", bg: "#FCE4EC", fg: "#fff", dark: true, css: "--color2", map: "pink lighten-5", icon: "pink lighten-1"},
        {color: "#AB47BC", bg: "#F3E5F5", fg: "#fff", dark: true, css: "--color3", map: "purple lighten-5", icon: "purple lighten-1"},
        {color: "#7E57C2", bg: "#EDE7F6", fg: "#fff", dark: true, css: "--color4", map: "deep-purple lighten-5", icon: "deep-purple lighten-1"},
        {color: "#7E57C2", bg: "#E8EAF6", fg: "#fff", dark: true, css: "--color5", map: "indigo lighten-5", icon: "indigo lighten-1"},
        {color: "#42A5F5", bg: "#E3F2FD", fg: "#fff", dark: true, css: "--color6", map: "blue lighten-5", icon: "blue lighten-1"},
        {color: "#29B6F6", bg: "#E1F5FE", fg: "#fff", dark: true, css: "--color7", map: "light-blue lighten-5", icon: "light-blue lighten-1"},
        {color: "#26C6DA", bg: "#E0F7FA", fg: "#fff", dark: true, css: "--color8", map: "cyan lighten-5", icon: "cyan lighten-1"},
        {color: "#26A69A", bg: "#E0F2F1", fg: "#fff", dark: true, css: "--color9", map: "teal lighten-5", icon: "teal lighten-1"},
        {color: "#66BB6A", bg: "#E8F5E9", fg: "#fff", dark: true, css: "--color10", map: "green lighten-5", icon: "green lighten-1"},
        {color: "#9CCC65", bg: "#F1F8E9", fg: "#888", dark: false, css: "--color11", map: "light-green lighten-5", icon: "light-green lighten-1"},
        {color: "#D4E157", bg: "#F9FBE7", fg: "#888", dark: false, css: "--color12", map: "lime lighten-5", icon: "lime lighten-1"},
        {color: "#FFEE58", bg: "#FFFDE7", fg: "#888", dark: false, css: "--color13", map: "yellow lighten-5", icon: "yellow lighten-1"},
        {color: "#FFCA28", bg: "#FFF8E1", fg: "#888", dark: false, css: "--color14", map: "amber lighten-5", icon: "amber lighten-1"},
        {color: "#FFA726", bg: "#FFF3E0", fg: "#fff", dark: true, css: "--color15", map: "orange lighten-5", icon: "orange lighten-1"},
        {color: "#FF7043", bg: "#FBE9E7", fg: "#fff", dark: true, css: "--color16", map: "deep-orange lighten-5", icon: "deep-orange lighten-1"},
        {color: "#8D6E63", bg: "#EFEBE9", fg: "#fff", dark: true, css: "--color17", map: "brown lighten-5", icon: "brown lighten-1"},
        {color: "#78909C", bg: "#ECEFF1", fg: "#fff", dark: true, css: "--color18", map: "blue-grey lighten-5", icon: "blue-grey lighten-1"},
        {color: "#BDBDBD", bg: "#FAFAFA", fg: "#fff", dark: true, css: "--color19", map: "grey lighten-5", icon: "grey lighten-1"},
        {color: "#000000", bg: "#888", fg: "#fff", dark: true, css: "--color20", map: "black", icon: "black"},
        {color: "#fff", bg: null, fg: "#888", dark: false, css: null, map: null},
    ],
    GROUP_AUTO_ARCHIVED_AFTER_DAYS: 45,

    //in _variables.scss, config.js and plugins/vuetify.js
    WIDTH_SM: 600,
    WIDTH_MD: 960 - 16,
    WIDTH_LG: 1280 - 16,
    WIDTH_XL: 1440 - 16,

    TODOS_MAX_MAIN: 7,
    TODOS_MAX_PRIO: 3,
    CONTEXT_MAX_TASKS: 100,

    DAY_LABELS: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вск"],

    AUTH_LENGTH: 16,
    CLIENT_ID_LENGTH: 16,
    CLIENT_ID_ALPHABET: "1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz_-",
    ANONYMOUS_USER_AUTH: "-___ANONYMOUS___",
}
