import Vue from 'vue';
import Anim from '@/components/elements/anim';
//import GroupTopicsDraggable from "@/components/context/context-topics-draggable";
import DialogDelete from "@/components/dialogs/dialog-delete";
import Loader from "@/components/elements/loader";
import Avatar from "@/components/elements/avatar";
import Logo from "@/components/elements/logo";
import ContextTreeLevel from "@/components/context/context-tree-level";

// default tags
//import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
Vue.component('anim', Anim);
//Vue.component('group-topics-draggable', GroupTopicsDraggable);
Vue.component('dialog-delete', DialogDelete);
Vue.component('loader', Loader);
Vue.component('avatar', Avatar);
Vue.component('logo', Logo);
Vue.component('context-tree-level', ContextTreeLevel);
