import Vue from "vue";
import Config from "@/config";
import {sortByAuth} from "@/utils/group";
import {date2Iso} from "@/utils/date";
import uiMutations from "@/store/ui/mutations";
import router from "@/router";
import {flatArray} from "@/utils/utils";
import {jointContexts, obtainPickSingleParent} from "@/utils/pick";
import {initGroupsInArea, initPicksInArea, initUserPicksInArea} from "@/utils/area";
import {sortByPrio} from "@/utils/task";
import {parseContextIds} from "@/utils/context";

const mutations = {
    contextItem: (state, payload) => {
        console.log("MUTATION context/item", payload);
    },
    contextCreate: (state, payload) => {
        console.log("MUTATION context/create", payload);

        // after creation - call this
        payload.mutation = "contextAppend";

        // after creation - broadcast to subscribers of this
        const broadcast = [{
            entity: "context",
            auth: payload.contextId,
        }];
        payload.broadcast = broadcast;
        console.log("broadcast", broadcast);
    },
    contextPick: (state, payload) => {
        console.log("MUTATION context/pick", payload);

        // after creation - call this
        payload.mutation = "contextAppend";

        // after creation - broadcast to subscribers of this
        const broadcast = [{
            entity: "context",
            auth: payload.contextId,
        }];
        payload.broadcast = broadcast;
        console.log("broadcast", broadcast);
    },
    contextAppend: (state, payload) => {
        console.log("MUTATION context/append", payload);

        const item = payload?.contextAppend?.item;
        if (!item) return;

        // todo
        //const contexts = payload?.append?.picks;

        const area = state.session.area;
        let items = area.picks;

        // insert item
        if (items?.findIndex(el => el.id === item.id) === -1) {
            items.push(item);
            console.log("APPENDED", item, items);
        }
    },
    contextUpdate: (state, payload) => {
        console.log("MUTATION context/update", payload);

        if (!payload?.contextId) return;
        if (!payload.params) return;

        const update = (item) => {
            Object.keys(payload.params).forEach(key => {
                //console.log("ITEM UPDATE: ", item, key, payload.params[key]);
                Vue.set(item, key, payload.params[key]);
            });
        }

        // update item in session
        const originalContextId = payload.contextId;
        //console.log("originalContextId: ", originalContextId);
        const {rootContextId, contextId} = parseContextIds(originalContextId);
        //console.log("\tcontextId: ", contextId);
        //const picks = flatArray(state.session.area.picks);
        //const userPicks = flatArray(state.session.area.userPicks);
        const area = state.session.area;
        const contexts = jointContexts(area);
        let item;
        if (contexts?.length) {

            const items = contexts.filter(el => {
                const contextIds = parseContextIds(el.contextId);
                return contextIds.contextId === contextId;
            });
            //console.log("ITEM CONTEXT", item);
            if (items?.length) {
                items.forEach(item => update(item));

                // todo do not call for all picks, but only for items?
                initPicksInArea(area);
            }
        }

        // update item in context
        console.log("\tcontext.contextId: ", state.page.context.item.contextId);
        if (state.page.context.item && (state.page.context.item.contextId === contextId || state.page.context.item.contextId === originalContextId)) {
            update(state.page.context.item);
        }
    },
    contextDelete: (state, payload) => {
        console.log("MUTATION context/delete", payload);

        // check we have this
        const contextId = payload.contextId;

        const area = state.session.area;
        const zones = [
            {list: area.picks},
            {list: area.userPicks},
            //{list: state.session.area.groups},
            //{list: state.session.area.topics},
            {list: area.tags},
        ];

        let context;
        for (let zone of zones) {
            if (!zone.list?.length) continue;

            console.log("ZONE", zone.list);
            const index = zone.list.findIndex(el => el.contextId === contextId);
            if (index === -1) continue;
            context = zone.list[index];

            // delete from area
            zone.list.splice(index, 1);
            console.log("DELETED " + index + ": " + contextId);
            break;
        }
        initGroupsInArea(area);
        initPicksInArea(area);
        initUserPicksInArea(area);

        if (!context) return;

        // clear context
        if (state.page.context.item?.contextId === context.contextId) {
            state.page.context.item = null;
            state.page.tasks.items = null;
        }

        // broadcast to subscribers of this
        const broadcast = [{
            entity: "context",
            auth: context.contextId,
        }];
        payload.broadcast = broadcast;
        console.log("broadcast", broadcast);

        if (router.currentRoute.params?.contextId === context.contextId) {
            router.push({name: "home"});
        }
    },
    contextChildren: (state, payload) => {
        console.log("MUTATION context/children", payload);

        const update = payload?.contextUpdate?.item;
        if (!update?.contextId) return;

        const area = state.session.area;

        const contexts = [
            ...area.picks,
            ...area.userPicks,
            /*...state.session.area.groups,
            ...state.session.area.topics,
            ...state.session.area.tags,*/
        ];
        if (!contexts?.length) return;
        const context = contexts.find(el => el.contextId === update.contextId);
        if (!context) return;

        let children;
        if (update.groupIds?.length) {
            // append child groups
            const groups = area.groups?.filter(group => update.groupIds.includes(group.id)).map(el => ({
                ...el,
                contextId: context.contextId + el.contextId,
                children: null
            }));

            let topics, tags;
            if (update.topicIds?.length) topics = area.topics?.filter(topic => update.topicIds.includes(topic.id));
            if (update.tagIds?.length) tags = area.tags?.filter(tag => update.tagIds.includes(tag.id));

            // append child topics
            if (topics?.length) {
                groups.forEach(group => {
                    Vue.set(group, "parent", context);

                    const groupTopics = topics.filter(topic => topic.groupId === group.id).map(el => ({
                        ...el,
                        contextId: context.contextId + el.contextId,
                        children: null
                    }));
                    sortByAuth(groupTopics, group.topicSort);
                    Vue.set(group, "children", groupTopics);
                    groupTopics?.forEach(el => Vue.set(el, "parent", group));

                    // append child tags
                    if (tags?.length) {
                        groupTopics.forEach(topic => {
                            const topicTags = tags.filter(tag => tag.topicId === topic.id).map(el => ({
                                ...el,
                                contextId: context.contextId + el.contextId,
                            }));
                            sortByAuth(topicTags, topic.tagSort, "id");
                            Vue.set(topic, "children", topicTags);
                            //console.log("topic tags", group.name+"/"+topic.name, topicTags);
                            topicTags?.forEach(el => Vue.set(el, "parent", topic));
                        });
                    }
                });
            }

            // collapse single groups/topics for group/topic picks
            children = groups;
            if (context.isPick) {
                const {group, topic, user} = obtainPickSingleParent(context, area);
                if (group && groups.length === 1) {
                    children = groups[0].children;
                    groups[0].children?.forEach(el => Vue.set(el, "parent", context));
                } else if (topic && groups.length === 1 && topics.length === 1) {
                    children = topics[0].children;
                    topics[0].children?.forEach(el => Vue.set(el, "parent", context));
                }
            }
        }
        Vue.set(context, "children", children);

        // update item in context
        //if (state.page.context.item && state.page.context.item.contextId === update.contextId) Vue.set(state.page.context.item, "children", children);;
    },
    contextSortTasksByPrio: (state, payload) => {
        console.log("MUTATION context/sortTasksByPrio", payload);
        if (!payload.contextUpdate?.item) return;

        const update = payload.contextUpdate?.item;
        const area = state.session.area;
        const contexts = jointContexts(area);
        if (!contexts?.length) return;
        const context = contexts.find(el => el.contextId === update.contextId);
        console.log("CONTEXT FOUND", update.contextId);
        console.log("CONTEXT FOUND", context);
        if (!context) return;
        context.taskSort = update.taskSort;

        if (state.page.context.item?.contextId) {
            const {rootContextId, contextId} = parseContextIds(state.page.context.item?.contextId);
            if (rootContextId === update.contextId) {
                console.log("CONTEXT TO SORT", state.page.context.item);
                state.page.context.item.taskSort = update.taskSort;
                sortByPrio(state.page.tasks.items);
            }
        }
    },

    /* --- TODO REVIEW --- */
    contextFav: (state, payload) => {
        console.log("MUTATION context/fav", payload);
        if (!payload?.params) return;

        // remove archivedAt if contextgoes to favs
        const params = payload.params;
        if (params.isFav) {
            params.isArchived = false;
            params.archivedAt = null;
        }

        mutations.contextUpdate(state, payload);
    },
    contextArchive: (state, payload) => {
        console.log("MUTATION context/archive", payload);
        if (!payload?.params) return;

        const params = payload.params;
        if (params.isArchived) {
            params.archivedAt = date2Iso(new Date());
            params._isArchived = true;
            //params.isFav = false;
        } else {
            params.archivedAt = null;
            params._isArchived = false;
        }

        mutations.contextUpdate(state, payload);
    },
    contextTaskSort: (state, payload) => {
        console.log("MUTATION context/taskSort", payload);

        const contextAuth = payload.contextAuth;
        const topicAuth = payload.topicAuth;
        if (!contextAuth && !topicAuth) return;

        const newTaskSort = payload.taskSort;
        if (!newTaskSort?.length) return;

        // check the requested context is ours
        let context;
        if (topicAuth && topicAuth === state.page.context.topic?.auth) context = state.page.context.topic;
        else if (!topicAuth && contextAuth && contextAuth === state.page.context.context?.auth) context = state.page.context.context;
        else return;

        //console.log("CONTEXT topic", state.page.context.topic);
        //console.log("CONTEXT context", state.page.context.context);

        // apply to tasks
        const action = () => {
            const tasks = state.page.tasks.items;
            sortByAuth(tasks, newTaskSort);
        }

        // find which task is moved
        /*const sortedTab = state.page.context.tab;
        let currentTaskSort = context.taskSort;
        if ( typeof currentTaskSort === "string" ) {
            try { currentTaskSort = JSON.parse(currentTaskSort);}
            catch (e) {return;}
        }
        currentTaskSort = Array.isArray(currentTaskSort) ? currentTaskSort : currentTaskSort[sortedTab];
        const movedItem = newTaskSort.find((el, i)=>el !== currentTaskSort[i]);
        console.log("Moved item", movedItem);*/
        //uiMutations.uiSetActorOfcontextTaskSort(state, {userAuth: payload.userAuth});
        if (payload.initiatorAuth && payload.taskAuth) {
            uiMutations.uiSetActorOfTask(state, {
                userAuth: payload.userAuth,
                subjectAuth: payload.taskAuth,
                icon: "mdi-hand-back-right-outline",
                timeout: 900,
            });
            setTimeout(action, 300);
        } else action();

        // todo apply to context.taskSort
        //Vue.set(context, "taskSort", context.taskSort);
        //console.log("context.taskSort", context.taskSort);

        /*const sourceIndex = payload.sourceIndex;
        const destinationIndex = payload.destinationIndex;
        const items = state.page.tasks.items;

        if (!items) return;
        if (sourceIndex < 0 || sourceIndex >= items.length) return;
        if (destinationIndex < 0 || destinationIndex >= items.length) return;

        if (sourceIndex !== destinationIndex) {
            const source = items[sourceIndex];
            items.splice(sourceIndex, 1);
            items.splice(destinationIndex, 0, source);
        }*/

        // broadcast to subscribers of this
        const broadcast = [{
            entity: "context",
            auth: contextAuth,
        }];
        if (topicAuth) broadcast.push({
            entity: "topic",
            auth: topicAuth,
        });
        payload.broadcast = broadcast;
        console.log("broadcast", broadcast);
    },
    contextSort: (state, payload) => {
        console.log("MUTATION context/sort", payload);
        const user = state.session.user;
        if (!user) return;

        const contexts = state.session.area.contexts;
        if (!contexts) return;

        sortByAuth(contexts, payload.contextSort);
        //console.log("AFTER sort", context.children.map(c=>c.auth));

        user.contextSort = payload.contextSort;
    },
    contextAssign: (state, payload) => {
        console.log("MUTATION context/assign", payload);
        if (!payload?.contextId) return;
        if (!payload?.assigneeAuth&&!payload?.ownerAuth) return;

        const originalContextId = payload.contextId;
        console.log("originalContextId: ", originalContextId);
        const {rootContextId, contextId} = parseContextIds(originalContextId);
        //const contextId = payload.contextId;
        const assigneeAuth = payload.assigneeAuth;
        const ownerAuth = payload.ownerAuth;

        let userId = null, ownerId = null;
        if (assigneeAuth) {
            const user = state.session.area.users.find(el => el.auth === assigneeAuth);
            console.log("USER FOUND", user);
            if (user) userId = user.id;
            else return;
        }

        let property = "userId";

        // update item in a area
        const area = state.session.area;
        const items = jointContexts(area);
        let item;
        if (items?.length) {
            item = items.find(el => el.contextId === contextId);
            if (item) item[property] = userId;

            console.log("CONTEXT FOUND", item);
        }

        // update item in context
        if (state.page.context.item?.contextId === contextId) state.page.context.item[property] = userId;

        // broadcast to subscribers of this
        /*const broadcast = [{
            entity: "context",
            auth: payload?.contextAuth,
        }];
        payload.broadcast = broadcast;
        console.log("broadcast", broadcast);*/
    },
};

export default mutations;
