<template>
	<div v-if="items?.length" class="context-tree-level" :class="componentClass">

		<div v-for="(item, index) of filteredItems" :key="'item'+index" class="context-tree-level__item"
			 :class="classOfItem(item)">
			<!-- archive toggler -->
			<!--			<v-btn v-if="item.isArchiveToggler" small block text class="caption grey&#45;&#45;text"
							   @click="item.onClick">
							<v-icon x-small class="mr-1" color="grey">
								mdi-archive-outline
							</v-icon>
							{{ item.parent.withArchivedChildren ? 'показать все' : 'скрыть архив' }}
						</v-btn>-->
			<draggable :data="dragDataOfContext(item)"
					   :zone="zoneOfContext(item)"
					   :accepts-zones="acceptedZonesOfContext(item)"
					   :enabled="isItemDraggable(item)"
					   class="__element --border-accent"
					   :class="classOfElement(item)"
					   @drop="onDrop">
				<component
					:is="componentOf(context)"
					:to="routeOfContext(item)"
					draggable="false" class="__link">

					<div @click="onBeforeRouting(item)" class="__content">
						<div class="__icon" @click.prevent.stop="onIconClick(item)">
							<loader v-if="item.contextId && item.contextId===pending.context?.contextId" small/>
							<!--							<v-icon v-else-if="item.onClick" small>
															{{ item.icon }}
														</v-icon>-->
							<avatar v-else-if="shouldBeWithAvatar(item)" :user="item" small/>
							<logo v-else-if="shouldBeWithLogo(item)" :item="item" small/>
							<!--							<i v-else-if="item.isTag" class="__tag">#</i>-->
							<v-icon v-else-if="item.isTag" small>mdi-pound</v-icon>
							<v-icon v-else small
									:color="colorOfContextIcon(item)"
									@click.prevent.stop="onToggleOpenContext(item, true)">
								{{ iconOfContext(item) }}
							</v-icon>
						</div>
						<div class="__name">
							<!--<v-img v-if="item.is1Task" src="@/assets/1task-logo.png" class="__1task"/>-->
							<v-icon v-if="item.is1Task" small>mdi-numeric-1-box-multiple-outline</v-icon>
							<v-icon v-else-if="item.isBlock" small>mdi-numeric-1-box-outline</v-icon>
							{{ nameOf(item) }}
						</div>
					</div>
				</component>
				<div v-if="isAuthed && item.contextId && !isReadonly" class="__menu">
					<v-icon small class="__menu-btn icon" @click="e=>onMenu(item, e)">mdi-dots-vertical</v-icon>
				</div>
			</draggable>
			<context-tree-level v-if="item.isOpen" :items="item.children" :params="params" :with-route="withRoute"
								:level="level+1" class="__children"/>
		</div>

		<div v-if="params.withArchive && hasArchive && !search" class="context-tree-level__item --archive-btn">
			<div class="__element --border-accent">
				<div class="__link pointer" @click="onToggleWithArchivedItems">
					<div class="__content">
						<div class="__icon">
							<v-icon small>
								mdi-archive-outline
							</v-icon>
						</div>
						<div class="__name">
							{{ withArchivedItems ? 'скрыть архив' : 'показать архив' }}
						</div>
					</div>
				</div>
			</div>
		</div>

		<dialog-group-settings :params="dialogGroupSettings"/>
		<!--		<dialog-delete :params="dialogDeleteParams"/>-->
	</div>
</template>

<script>
import DialogGroupSettings from '@/components/dialogs/dialog-group-settings';
import Mutation from "@/components/elements/mutation";
import draggable from '@/components/elements/draggable';
import storage from "@/utils/storage";
import Config from "@/config";
import {iso2Date} from "@/utils/date";
import Vue from "vue";
import base from "@/mixins/base";
import {pickSingleIntValue} from "@/utils/pick";

export default {
	name: "context-tree-level",
	components: {DialogGroupSettings, Mutation, draggable},
	props: {
		items: {},
		level: {
			type: Number,
			default: 0,
		},
		params: {},
		withRoute: {
			type: Boolean,
			default: false
		}
	},
	mixins: [base],
	data() {
		return {
			withArchivedItems: false,
			dialogGroupSettings: {
				isVisible: false,
			},
			userMenuParams: {
				isVisible: false,
				x: 0,
				y: 0,
				user: null,
				item: null
			},
		}
	},
	computed: {
		isReadonly() {
			return this.params.readonly;
		},
		assignee() {
			return this.page?.assignee;
		},
		zonePrefix() {
			return this.params.zonePrefix;
		},
		hasArchive() {
			return !!this.items?.find(el => el._isArchived);
		},
		filteredItems() {
			if (this.search) {
				const re = new RegExp(this.search, "i");
				return this.items?.filter(el => {
					return el.name?.match(re)
						// for user picks
						|| el.nick?.match(re)
						|| el.firstName?.match(re)
						|| el.lastName?.match(re);
				});
			}
			if (this.withArchivedItems) return this.items;
			return this.items?.filter(el => !el._isArchived);
		},
		users() {
			return this.params.users;
		},
		groupMenuParams() {
			return this.$store.state.group.groupMenuParams;
		},
		selectedContextId() {
			return this.params.item?.contextId;
			//return this.context.item?.contextId;
		},
		componentClass() {
			const c = [];
			c.push("--level-" + this.level);
			//if (!!this.withRoute) c.push("--with-route");
			return c.length ? c : null;
		},
	},
	watch: {},
	methods: {
		onDrop(source, destination) {
			console.log("onDrop source", source);
			console.log("onDrop dest", destination);
			if (source.zone.match(/.+?-topics$/) && source.zone === destination.zone) {
				// topic d-n-d sorting
				let sourceContextId = source.data.contextId;
				if (sourceContextId.length === Config.AUTH_LENGTH * 2) sourceContextId = sourceContextId.substring(Config.AUTH_LENGTH);
				const topic = this.topics.find(el => el.contextId === sourceContextId);
				//console.log("topic", topic);
				if (!topic) return;
				const group = this.groups.find(el => el.id === topic.groupId);
				//console.log("group", group);
				const topicsInTree = group.children;
				const sourceIndex = topicsInTree.findIndex(el => el.contextId === sourceContextId);
				let destinationContextId = destination.data.contextId;
				if (destinationContextId.length === Config.AUTH_LENGTH * 2) destinationContextId = destinationContextId.substring(Config.AUTH_LENGTH);
				const destinationIndex = topicsInTree.findIndex(el => el.contextId === destinationContextId);
				//console.log("sourceIndex", sourceIndex);
				//console.log("destinationIndex", destinationIndex);
				/*source = topicsInTree[sourceIndex];
				topicsInTree.splice(sourceIndex, 1);
				topicsInTree.splice(destinationIndex, 0, source);*/

				const topicSort = topicsInTree.map(el => el.auth);
				source = topicSort[sourceIndex];
				topicSort.splice(sourceIndex, 1);
				topicSort.splice(destinationIndex, 0, source);

				return this.do('group/topicSort', {
					groupAuth: group.auth,
					topicSort
				});
			} else if (source.zone.match(/.+?-tags$/) && source.zone === destination.zone) {
				// tags d-n-d sorting
				let sourceContextId = source.data.contextId;
				if (sourceContextId.length === Config.AUTH_LENGTH * 2) sourceContextId = sourceContextId.substring(Config.AUTH_LENGTH);
				const item = this.tags?.find(el => el.contextId === sourceContextId);
				//console.log("item", item);
				if (!item) return;
				const parent = this.topics?.find(el => el.id === item.topicId);
				if (!parent) return;
				const children = parent.children;
				console.log("parent", parent.children.map(el => el.auth));
				const sourceIndex = children.findIndex(el => el.contextId === sourceContextId);
				let destinationContextId = destination.data.contextId;
				if (destinationContextId.length === Config.AUTH_LENGTH * 2) destinationContextId = destinationContextId.substring(Config.AUTH_LENGTH);
				const destinationIndex = children.findIndex(el => el.contextId === destinationContextId);
				console.log("sourceIndex", sourceIndex);
				console.log("destinationIndex", destinationIndex);
				/*source = topicsInTree[sourceIndex];
				topicsInTree.splice(sourceIndex, 1);
				topicsInTree.splice(destinationIndex, 0, source);*/

				const itemSort = children.map(el => el.auth);
				source = itemSort[sourceIndex];
				itemSort.splice(sourceIndex, 1);
				itemSort.splice(destinationIndex, 0, source);

				return this.do('topic/tagSort', {
					topicAuth: parent.auth,
					tagSort: itemSort
				});
			} else if (source.zone.match(/.+?-groups$/) && source.zone === destination.zone) {
				// group d-n-d sorting
				//const tree = this.params.tree;
				const destinationIndex = this.groups.findIndex(el => el.contextId === destination.data?.contextId);
				const sourceIndex = this.groups.findIndex(el => el.contextId === source.data?.contextId);
				//console.log("sourceIndex", sourceIndex);
				//console.log("destinationIndex", destinationIndex);
				/*source = tree[sourceIndex];
				tree.splice(sourceIndex, 1);
				tree.splice(destinationIndex, 0, source);*/

				const groupSort = this.groups.map(el => el.auth);
				source = groupSort[sourceIndex];
				groupSort.splice(sourceIndex, 1);
				groupSort.splice(destinationIndex, 0, source);

				//console.log("groupSort", groupSort);
				//return;

				return this.do('user/groupSort', {
					groupSort
				});

				//this.submitTopicSort(group, topics);
				/*return this.$store.dispatch('user/groupSort', {
					ids: tree.map(el => el.id)
				}).then(() => this.$emit("updated"));*/
			} else if (source.zone.match(/.+?-picks$/) && source.zone === destination.zone) {
				// context d-n-d sorting
				//const tree = this.params.tree;
				const destinationIndex = this.picks.findIndex(el => el.contextId === destination.data?.contextId);
				const sourceIndex = this.picks.findIndex(el => el.contextId === source.data?.contextId);
				//console.log("sourceIndex", sourceIndex);
				//console.log("destinationIndex", destinationIndex);

				const pickSort = this.picks.map(el => el.contextId);
				source = pickSort[sourceIndex];
				pickSort.splice(sourceIndex, 1);
				pickSort.splice(destinationIndex, 0, source);

				//console.log("groupSort", groupSort);
				//return;

				return this.do('user/pickSort', {
					pickSort
				});

				//this.submitTopicSort(group, topics);
				/*return this.$store.dispatch('user/groupSort', {
					ids: tree.map(el => el.id)
				}).then(() => this.$emit("updated"));*/
			} else if (source.zone.match(/.+?-users$/) && source.zone === destination.zone) {
				// users d-n-d sorting
				const destinationIndex = this.userPicks.findIndex(el => el.contextId === destination.data?.contextId);
				const sourceIndex = this.userPicks.findIndex(el => el.contextId === source.data?.contextId);
				//console.log("sourceIndex", sourceIndex);
				//console.log("destinationIndex", destinationIndex);

				const favUserSort = this.userPicks.map(el => el.contextId);
				const sourceAuth = favUserSort[sourceIndex];
				favUserSort.splice(sourceIndex, 1);
				favUserSort.splice(destinationIndex, 0, sourceAuth);

				//console.log("groupSort", groupSort);
				//return;

				return this.do('user/favUserSort', {
					userAuth: sourceAuth,
					favUserSort
				});

				//this.submitTopicSort(group, topics);
				/*return this.$store.dispatch('user/groupSort', {
					ids: tree.map(el => el.id)
				}).then(() => this.$emit("updated"));*/
			} else if (source.zone === "tasks") {
				// processed in context-tasks.vue
			}
		},
		async onToggleOpenContext(context, isIconClick = false) {
			//console.log("onToggleOpenContext", context);
			if (typeof context.children === "undefined" && !this.level) await this.fetchChildrenOfContext(context);

			if (context.isOpen) {
				// close only if same context clicked twice or icon clicked
				if (this.params.item?.contextId === context.contextId || isIconClick) Vue.set(context, "isOpen", false);
			} else {
				// always open clocsed contexts on click
				Vue.set(context, "isOpen", true);
			}
		},
		onGroupSettings(item) {
			this.dialogGroupSettings.group = item;
			this.dialogGroupSettings.isVisible = true;
		},
		onIconClick(item) {
			if (typeof item.onClick === "function") return item.onClick(item);
			this.onToggleOpenContext(item, true);
		},
		onMenu(item, event) {
			event.preventDefault();

			this.$store.dispatch("ui/contextMenu", {
				event,
				item,
			});
		},
		onBeforeRouting(context) {
			this.onToggleOpenContext(context);
			if (context.contextId && context.contextId === this.params.item?.contextId) {
				// clicked same context - just toggle it
				return;
			}
			if (typeof this.params.onClick === "function") {
				this.params.onClick(context);
				return;
			}

			this.commit("uiSetPendingContext", context);
		},
		onToggleWithArchivedItems() {
			this.withArchivedItems = !this.withArchivedItems;
		},
		componentOf(item) {
			if (typeof this.params.onClick === "function") return "div";
			return "router-link";
		},
		dragDataOfContext(item) {
			return {
				name: item.name,
				contextId: item.contextId,
			};
		},
		isItemDraggable(item) {
			if (item.isArchiveToggler) return false;
			if (this.params.onClick) return false;
			if (this.params.withDraggableContexts) {
				if (item.contextId) return true;
			}
			return false;
		},
		shouldBeWithAvatar(item) {
			return item.avatarName
				|| item.avatar
				|| item.isUser;
		},
		shouldBeWithLogo(item) {
			return item.shouldBeWithLogo
				|| item.image
				|| item.isGroup;
		},
		nameOf(item) {
			if (item.isUser) return this.nickOf(item);
			return item.name;
		},
		iconOfContext(item) {
			//if (item.isPick) return item.icon || "mdi-folder-star";
			if (item.emoji) return item.emoji;
			if (item.icon) return item.icon;
			if (item.children?.length) {
				if (item.isOpen) return 'mdi-folder-open-outline';
				//return open ? 'mdi-folder-open-outline' : (item.icon || 'mdi-folder-multiple-outline');
			}
			return 'mdi-folder-outline';
		},
		colorOfContextIcon(item) {
			//if (!item.isContext) return null;
			if (!item.color) return null;
			const color = Config.COLORS.find(el => el.css === item.color);
			return color?.icon;
		},
		classOfItem(item) {
			const c = [];
			return c.length ? c : null;
		},
		classOfElement(item) {
			//if (!item.isContext) return null;
			const c = [];
			//c.push("--context");
			if (item.color) c.push(item.color);
			//c.push("--context_" + item.contextId);
			if (this.selectedContextId === item.contextId) c.push("--selected");
			if (item.isTag) c.push("--tag");
			if (!!item._isArchived) {
				c.push("--archived");
				if (item.parent?.withArchivedChildren) c.push("--hidden");
			}
			return c.length ? c : null;
		},
		zoneOfContext(item) {
			let name = "picks";
			if (item.isGroup) name = "groups";
			else if (item.isTopic) name = item.groupId + "-topics";
			else if (item.isTag) name = item.groupId + "." + item.topicId + "-tags";
			else if (item.isUser) name = "users";
			return this.zonePrefix + "-" + name;
		},
		acceptedZonesOfContext(item) {
			const zones = [this.zoneOfContext(item)];
			if (item.isGroup || item.isTopic || item.isUser || item.isTag) zones.push("tasks");
			else if (item.isPick) {
				const groupId = pickSingleIntValue(item, "groupId");
				const topicId = pickSingleIntValue(item, "topicId");
				const userId = pickSingleIntValue(item, "userId");
				if (groupId || topicId || userId) zones.push("tasks");
			}
			return zones;
		},
	}
}
</script>

<style lang="scss">
.context-tree-level {
	//@include transition();

	&.--level {
		&-1 {
			.__element {
				padding-left: 2.4rem;
			}
		}

		&-2 {
			.__element {
				padding-left: 4.1rem;
			}

			.__name {
				font-size: $font-size-4 !important;
				min-height: 24px !important;
			}

			.__icon {
				margin-top: 1px !important;
			}
		}

		&-3 {
			.__element {
				padding-left: 5.9rem;
			}
		}
	}

	&__item {
		.__element {
			//min-height: 28px;
			display: flex;
			//align-items: center;
			padding: 0 0.4rem 0 .5rem;
			@include transition();

			&:hover {
				background: map-get($grey, "lighten-5");

				a {
					opacity: 1 !important;
				}

				.__menu-btn {
					opacity: 1;
				}
			}

			.__link {
				color: $default-text-color !important;;
				display: flex;
				//height: 100%;
				flex: 1;
				line-height: 1.3rem;
				cursor: pointer;

				&:hover {
					color: $black !important;
				}

				.__content {
					flex: 1;
					display: flex;
					align-items: flex-start;
					gap: 0.5rem;

					.__icon {
						width: 24px;
						height: 24px;
						margin-top: 4px;
						flex-shrink: 0;
						display: flex;
						justify-content: center;
						align-items: center;

						/*i.__tag {
							opacity: $default-icon-opacity;
							display: inline-block;
							font-style: normal !important;
							margin: 0 4px 0 4px;
						}*/
					}

					.__name {
						display: flex;
						align-items: flex-start;
						flex: 1;
						min-height: 32px;
						padding: 6px 0 0 0;
						font-size: $font-size-3;

						.v-icon {
							margin: 3px 5px 0 0;
						}
					}
				}
			}

			.__menu {
				flex: 0;
				//display: flex;
				//align-items: center;
			}

			&.--selected {
				background: white;

				.__link {
					font-weight: bold;
				}
			}

			&.--archived {
				.__link {
					opacity: $default-disabled-opacity;
				}
			}
		}
	}

	.__menu-btn {
		margin-top: 3px;
		opacity: 1;

		@include up($md) {
			opacity: 0;
		}
	}

	.--archive-btn {
		margin: 0.25rem 0 .5rem 0;

		.__name {
			font-size: $font-size-4 !important;
		}

		.v-icon {
			opacity: $default-disabled-opacity;
		}
	}

	.v-image.__1task {
		max-width: 12px;
		height: 12px;
		margin-right: 6px;
	}

}
</style>
