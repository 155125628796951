<template>
	<v-dialog v-model="params.isVisible"
			  content-class="dialog-group-settings">
		<v-card class="text-center">
			<v-card-title class="text-center">
				Настройки группы {{ name }}
			</v-card-title>

			<v-card-text>
				<v-select
					v-model="form.overview"
					:items="overviewItems"
					label="Периодический обзор"
					hide-details
					@change="onoverviewChange"/>

				<div v-if="form.overview==='weekly'" class="mt-4">
					<div>Выберите дни недели</div>
					<div class="d-flex flex-wrap">
						<div v-for="(day, index) of dayLabels" :key="'day'+index" class="dialog-group-settings__day">
							<v-checkbox v-model="form.overviewDays[index]"
										:label="day"
										hide-details/>
						</div>
					</div>
				</div>
				<div v-else-if="form.overview==='monthly'" class="mt-4">
					<div>Выберите дни месяца</div>
					<div class="d-flex flex-wrap">
						<div v-for="(day, index) of 31" :key="'day'+index" class="dialog-group-settings__day">
							<v-checkbox v-model="form.overviewDays[index]"
										:label="''+day"
										hide-details/>
						</div>
					</div>
				</div>
				<div v-if="form.overview&&form.overview!=='hourly'" class="mt-4">Повторения происходят в 10
					утра.
				</div>

				<v-divider />

				<v-checkbox
					v-model="form.withTodoClickDone"
					label="Закрывать в Telegram задачи по клику "
					hide-details/>

			</v-card-text>

			<v-divider/>

			<v-card-actions>
				<v-btn
					text
					@click="params.isVisible = false">
					Отмена
				</v-btn>
				<v-spacer/>
				<v-btn
					color="primary"
					text
					:loading="isLoading"
					@click="onOk">
					Да!
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import Vue from "vue";
import {date2HHMMSS, date2Mysql} from "@/utils/date";
import Config from "@/config";

export default {
	name: "dialog-group-settings",
	props: {
		params: {},
	},
	data: () => ({
		isLoading: false,
		form: {
			overview: null,
			overviewDays: [],
			withTodoClickDone: true,
		},
		dayLabels: Config.DAY_LABELS,
		overviewItems: [
			{
				text: "Не выводить",
				value: null
			},
			{
				text: "Каждый час",
				value: "hourly"
			},
			{
				text: "Каждый день",
				value: "daily"
			},
			{
				text: "Выбрать дни недели",
				value: "weekly"
			},
			{
				text: "Выбрать дни месяца",
				value: "monthly"
			}
		],
	}),
	computed: {
		group() {
			return this.params?.group;
		},
		name() {
			return this.group?.name;
		},
	},
	watch: {
		value: {
			immediate: true,
			handler() {
				this.visible = this.value;
			}
		},
		visible() {
			this.$emit("input", this.visible);
		}
	},
	methods: {
		onoverviewChange() {

		},
		async onOk() {
			if (this.params.callback) {
				this.isLoading = true;
				await this.params.callback(this.params);
			}
			this.isLoading = false;
			this.params.isVisible = false;
			this.$emit("ok");
		},
		onCancel() {
			this.params.isVisible = false;
			this.$emit("cancel");
		}
	}
}
</script>

<style lang="scss">
.dialog-group-settings {
	max-width: 640px;

	&__day {
		width: 80px;
	}
}
</style>