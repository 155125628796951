/**
 * Returns merged objects as a sibgle object.
 * TS so far does not support this out of the box.
 */
import config from '@/config';

export function mergeObjects(...args) {
    let target = args[0];
    for (let i = 1; i < arguments.length; i++) {
        let source = arguments[i];
        for (let key in source) {
            if (source.hasOwnProperty(key)) {
                target[key] = source[key];
            }
        }
    }
    return target;
}

export function debounce(func, wait, immediate) {
    let timeout;
    return function () {
        let context = this, args = arguments;
        let later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        let callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

export function scroll(id, offset = 0, behavior = "smooth", target) {
    target = target || window;
    const el = document.getElementById(id);
    if (!el) return;
    if (target === window) {
        const top = el.getBoundingClientRect().top + window.scrollY + offset;
        window.scrollTo({
            top,
            behavior
        });
    } else {
        // todo
        const top = el.offsetTop + offset;
        console.log(el.getBoundingClientRect().top, target.offsetTop, offset)
        console.log("EL", top);

        target.scroll({
            top,
            behavior
        });
    }
}

export function crossDownload(url) {
    const req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.responseType = "blob";

    // todo
    //let authToken = localStorage.getItem(config.STORAGE_AUTH_TOKEN);
    //req.setRequestHeader('Authorization', authToken);

    req.onload = function (event) {
        const blob = req.response;
        let fileName = req.getResponseHeader('Content-Disposition').split("filename=")[1];

        fileName = decodeURI(fileName.replace(/['"]/g, ''))
        const __fileName = fileName;
        const contentType = req.getResponseHeader("content-type");
        if (window.navigator.msSaveOrOpenBlob) {
            // Internet Explorer
            window.navigator.msSaveOrOpenBlob(new Blob([blob], {type: contentType}), fileName);
        } else {
            const link = document.createElement('a');
            document.body.appendChild(link);
            link.download = __fileName;
            link.href = window.URL.createObjectURL(blob);
            link.click();
            document.body.removeChild(link); //remove the link when done
        }
    };
    req.send();
};

export function isLinkValid(str) {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
};

export function copy(text) {
    let textarea = document.createElement('textarea');
    textarea.textContent = text;
    document.body.appendChild(textarea);
    const selection = document.getSelection();
    const range = document.createRange();
    range.selectNode(textarea);
    selection.removeAllRanges();
    selection.addRange(range);
    //console.log('copy success', document.execCommand('copy'));
    document.execCommand('copy');
    selection.removeAllRanges();
    document.body.removeChild(textarea);
}

export function isIOS() {
    const iOSMobile = [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
    //console.log("IsIos iOSMobile ", iOSMobile);
    return iOSMobile;
}

export function isMacOrIOs() {
    return /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform) || isIOS();
}

export function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

export function flatArray(items, prop="children") {
    if (!items) return null;
    //console.log("STEP: ", items);
    return items.reduce((all, item) => {
        if (!item[prop]) return [...all, item];
        return [...all, item, ...flatArray(item[prop])];
    }, []);
}

export function parentsOf(item, prop="parent") {
    if (!item?.parent) return null;
    let parents = [item.parent];
    if ( item.parent.parent ) parents = parents.concat(parentsOf(item.parent));
    return parents;
}