import Vue from "vue";
import store from "@/store";
import Config from "@/config";
import {nick} from "@/utils/user";
import {date2Mysql, iso2Date, iso2DDMMYYYY, iso2HHMM, mysql2Date} from "@/utils/date";
import router from "@/router";
import {
    sortItems,
    colors,
    TABS,
    contextHasDelayedTill,
    rootContextId,
    sortByPrio,
    routeForContextFirstActiveTask,
    groupOfTask,
    topicOfTask,
    tagsOfTask,
    contextsOfTask,
    isTaskOfMyAttention,
    isTaskOverdue,
    isTaskDelayed,
    isTaskDone,
    isContextTaskInactive,
    isTaskInactive, isTaskTemplateActive, countContextInactiveTasks, countContextActiveTasks, contextFirstActiveTask
} from "@/utils/task";
import {parseContextIds} from "@/utils/context";
import {jointContexts} from "@/utils/pick";

/**
 * Base component.
 * Encapsulates some widely used computed variables and methods.
 */
export default {
    data() {
        return {
            COLOR_TASK_TEMPLATE: colors.TASK_TEMPLATE,
            COLOR_TASK_TEMPLATE_STOPPED: colors.TASK_TEMPLATE_STOPPED,

            tabItems: [{
                id: "actual",
                isActual: true,
                icon: "mdi-format-list-numbered",
                title: "Актуальные задачи",
                label: "Актуальные",
                //withTodos: true,
            },  {
                id: "notes",
                icon: "mdi-pin-outline",
                title: "Заметки",
                label: "Заметки",
                //withTodos: true,
            }, {
                id: "delayed",
                icon: "mdi-pause",
                title: "Задачи на паузе",
                label: "На паузе",
                //withTodos: true,
            }, {
                id: "done",
                icon: "mdi-check",
                title: "Выполненные задачи",
                label: "Выполненные",
                //withTodos: true,
            }, {
                id: "templates",
                icon: "mdi-view-grid-plus-outline",
                title: "Шаблоны",
                label: "Шаблоны",
                //withTodos: true,
            },],
        }
    },
    computed: {
        isError() {
            return !!this.$store.state.lastError;
        },
        lastError() {
            return this.$store.state.lastError;
        },
        hasData() {
            return this.session.isInited && this.page.isInited && !this.is403 && !this.is404;
        },
        hasSessionData() {
            return this.session.isInited;
        },
        hasContextData() {
            return this.session.isInited && this.context.isInited;
        },

        // session --------------------

        session() {
            return this.$store.state.session;
        },
        user() {
            return this.session.user;
        },
        currentUserNick() {
            return nick(this.user);
        },
        currentUserName() {
            return this.user.firstName || this.user.name;
        },
        workspace() {
            return this.session.workspace;
        },
        onlineUsers() {
            return this.session.area?.onlineUsers?.filter(el => el.auth !== this.user?.auth);
        },
        /**
         * Current user has full access.
         * @returns {boolean}
         */
        isAuthorized() {
            //return this.$store.state.isAuthorized;
            return !this.isLimitedAccess;
        },
        /**
         * Current user has full access.
         * Alias for isAuthorized.
         * @returns {boolean}
         */
        isAuthed() {
            return this.isAuthorized;
        },
        isAnon() {
            return this.session.isAnon;
        },
        is403() {
            return this.session.isAnon && (
                !this.isRouteTask
            );
        },
        is404() {
            // todo
            return false;
        },

        // ui -------------------
        ui() {
            return this.$store.state.ui;
        },
        pending() {
            return this.ui.pending;
        },

        withGroupFullAccess() {
            return !this.pageGroup || this.pageGroup.isFullAccess;
        },
        withTaskFullAccess() {
            return this.task.isFullAccess;
        },
        isLimitedAccess() {
            return this.isAnon || (
                this.context.isInited && !this.context.isFullAccess//!this.group && !this.topic && !this.isRouteMy
            );
        },
        isFullAccess() {
            return !this.isLimitedAccess;
            /*return (this.withGroupFullAccess && (!this.isRouteTask || this.withTodoFullAccess))
                || (this.isRoute1Task && this.withTodoFullAccess);*/
        },
        search: {
            get() {
                return this.ui.search.value;
            },
            set(v) {
                this.$store.commit("uiSetSearch", v);//$store.state.ui.search.value = v;
            }
        },
        isSearching() {
            return this.ui.search.isSearching;
        },
        is1TaskMode() {
            return this.isRoute1Task
                || (this.isRouteContext && this.context.item?.is1Task);
        },
        isBlockMode() {
            return this.isRoute1Task
                || (this.isRouteContext && this.context.item?.isBlock);
        },

        // area ---
        area() {
            return this.session.area;
        },
        workspaces() {
            return this.area?.workspaces;
        },
        picks() {
            return this.area?.picks;
        },
        userPicks() {
            return this.area?.userPicks;
        },
        contexts() {
            return jointContexts(this.area);
        },
        groups() {
            return this.area?.groups;
        },
        topics() {
            return this.area?.topics;
        },
        tags() {
            return this.area.tags;
        },
        users() {
            return this.area?.users;
        },

        // page -------------------
        page() {
            return this.$store.state.page;
        },
        tasks() {
            return this.page.tasks;
        },
        visibleTasks() {
            let items = this.page.tasks.items;
            if (!items?.length) return items;

            const context = this.context.item;
            //console.log("CONTEXT", context);
            if (context) {
                //if (!context.is1Task && !context.isBlock) return items;

                //const treeContext = this.contexts.find(el => el.contextId === context.contextId);

                // remove from tasks not first tasks in 1task/block
                items = items.filter(task => {
                    //console.group("TASK " + clearName(task.name));
                    const cs = contextsOfTask(task);
                    // always add this context to cs
                    if (!cs.find(el => el.contextId === context.contextId)) cs.push(context);
                    //console.log("contexts: " + cs.map(c => c.name + ":" + (c.is1Task || c.isBlock)));
                    const shouldBeFiltered = !!cs.find(c => c.is1Task || c.isBlock);
                    //console.log("shouldBeFiltered: " + shouldBeFiltered);
                    if (!shouldBeFiltered) {
                        //console.groupEnd();
                        return true;
                    }

                    // the task belongs at least to one limited context
                    const contextWhereTaskIsNotFirst = cs.find(c => {
                        if (c.is1Task || c.isBlock) {
                            const firstTask = contextFirstActiveTask(c);
                            return firstTask && firstTask.auth !== task.auth;
                        }
                        return false;
                    });

                    //if (contextWhereTaskIsNotFirst) console.log("not first here: " + contextWhereTaskIsNotFirst.name);
                    //else console.log("first everywhere");

                    //console.groupEnd();
                    return !contextWhereTaskIsNotFirst;
                });

                //console.log("ITEMS FILTERED!!!", items.length);
            }
            return items?.slice(0, Config.CONTEXT_MAX_TASKS);
        },

        // context ---
        context() {
            //console.log("REQUESTING context", this.page.context);
            return this.page.context;
        },
        fullContextItem() {
            const item = this.context.item;
            if (!item) return null;
            const {rootContextId, contextId} = parseContextIds(item.contextId);
            return jointContexts(this.area).find(el => el.contextId === contextId);
        },
        pick() {
            return this.context.pick;
        },
        group() {
            return this.context.group;
        },
        topic() {
            return this.context.topic;
        },
        tag() {
            return this.context.tag;
        },
        usersOfGroup() {
            return this.context.usersOfGroup;
        },
        tab: {
            get() {
                return this.context.tab
            },
            set(v) {
                return this.context.tab = v;
            }
        },
        tabItem() {
            return this.tabItems.find(el => el.id === this.tab);
        },
        assignee() {
            return this.context.assignee;
        },

        // msgs -------------------
        msgs() {
            return this.page.msgs;
        },

        // task -------------------
        task() {
            return this.page.task;
        },

        // draggable --------------------
        draggedElement() {
            return this.$store.state.draggable.lastDragElement;
        },

        // routing --------------------
        isRouteHome() {
            return this.$route.name === "home";
        },
        isRouteMy() {
            const thisUserAuth = this.$store.state.session.user?.auth;
            return (this.$route.name === "assignee" && thisUserAuth === this.$route.params.assigneeAuth)
                || (this.isRouteAssigneeTask && thisUserAuth === this.$route.params.assigneeAuth)
                || this.isRouteMyTask
                || this.isRouteMyTaskNew
                || (this.$route.name === "my" && !!thisUserAuth);
        },
        isRouteMyTask() {
            return this.$route.name === "my-task";
        },
        isRouteContext() {
            return this.$route.name === "context" || this.$route.name === "context-done" || this.isRouteContextTask;
        },
        isRouteContextTask() {
            return (this.$route.name === "context-task");
        },
        isRouteRecent() {
            return this.$route.name === "recent";
        },
        isRoutePrio() {
            return this.$route.name === "prio" || this.isRoutePrioTodo || this.isRoutePrioTodoNew;
        },
        isRouteTask() {
            return this.isRouteContextTask || this.isRoute1Task;
        },
        isRouteTaskNew() {
            return this.isRouteTopicTaskNew
                || this.isRouteGroupTaskNew
                || this.isRouteMyTaskNew
                || this.isRouteAssigneeTaskNew
                || this.isRoutePrioTodoNew;
            //|| this.isRouteSearchTaskNew;
        },
        isRouteTopic() {
            return this.$route.name === "topic" || this.$route.name === "topic-done" || this.isRouteTopicTask;
        },
        isRouteTopicTask() {
            return (this.$route.name === "topic-task");
        },
        isRouteTopicTaskNew() {
            return this.$route.name === "topic-task-new";
        },
        isRouteGroup() {
            return this.$route.name === "group" || this.$route.name === "group-done" || this.isRouteGroupTask;
        },
        isRouteGroupTask() {
            return (this.$route.name === "group-task");
        },
        isRouteGroupTaskNew() {
            return !!this.group && this.$route.name === "group-task-new";
        },
        isRouteAssignee() {
            const thisUserAuth = this.$store.state.session.user?.auth;
            return (this.$route.name === "assignee"
                || this.isRouteAssigneeTask) && thisUserAuth !== this.$route.params.assigneeAuth;
        },
        isRouteAssigneeTask() {
            return this.$route.name === "assignee-task" || this.$route.name === "assignee-task-new";
        },
        isRouteAssigneeTaskNew() {
            return this.$route.name === "assignee-task-new";
        },
        isRouteMyTaskNew() {
            return this.$route.name === "my-task-new";
        },
        isRoutePrioTodo() {
            return this.$route.name === "prio-task";
        },
        isRoutePrioTodoNew() {
            return this.$route.name === "prio-task-new";
        },
        isRouteSearchTask() {
            return this.$route.name === "search-task";
        },
        isRouteUsers() {
            return this.$route.name === "users";
        },
        isRouteSearch() {
            return this.$route.name === "search" || this.isRouteSearchTask;
        },
        isRoute1Task() {
            return this.$route.name === "home-task"
            || this.$route.name === "home-done";
        },
        isRoute1TaskDone() {
            return this.$route.name === "home-done";
        },
        isRouteContextDone() {
            return this.isRoute1TaskDone
                || this.$route.name === "context-done";
        },
        parentListRoute() {
            if (this.isRouteGroupTask) return {
                name: "group",
                params: {groupAuth: this.group.auth},
                query: {tab: this.$route.query?.tab}
            };
            if (this.isRouteTopicTask) return {
                name: "topic",
                params: {topicAuth: this.topic.auth},
                query: {tab: this.$route.query?.tab}
            };
            if (this.isRouteAssigneeTask) return {
                name: "assignee",
                params: {assigneeAuth: this.assignee.auth},
                query: {tab: this.$route.query?.tab}
            };
            if (this.isRouteSearchTask) return {
                name: "search",
                query: {s: this.$route.query?.s, tab: this.$route.query?.tab}
            };
        }
    },
    /*	beforeRouteUpdate(to, from, next){
            console.log("beforeRouteUpdate", to, from);
            //initAuthorization(to);
            next();
        }*/
    methods: {
        do(action, params) {
            return this.$store.dispatch("action", {
                action,
                params
            });
        },
        dispatch(name, params) {
            return this.$store.dispatch(name, params);
        },
        commit(name, payload) {
            return this.$store.commit(name, payload);
        },
        fetchChildrenOfContext(item) {
            //console.log("fetchChildrenOfContext", item);
            return this.do("context/children", {
                contextId: item.contextId
            });
        },
        toggleTaskDone(item) {
            let params;
            if (this.isTaskDone(item))
                params = {
                    isDone: false,
                    isActive: true,
                    doneAt: null
                };
            else params = {
                isDone: true,
                isActive: false,
            }
            return this.do("task/update", {
                taskAuth: item.auth,
                params
            });
        },
        toggleContext1TaskMode(item) {
            item = item || this.context.item;
            if (!item) return;
            const willBe1Task = !item.is1Task;
            return this.do("context/update", {
                contextId: item.contextId,
                params: {
                    is1Task: !item.is1Task,
                    isBlock: false,
                }
            }).then(() => {
                if (this.context.item?.contextId === item.contextId) {
                    // we are updating current context
                    if (willBe1Task) {
                        if ( !this.isRouteTask ) {
                            const redirect = routeForContextFirstActiveTask();
                            if (redirect) {
                                //small pause for animation
                                setTimeout(() => {
                                    this.$router.push(redirect)
                                }, 300);
                            }
                        }
                    }
                }
            });
        },
        toggleContextBlockMode(item) {
            item = item || this.context.item;
            if (!item) return;
            const willBeBlock = !item.isBlock;
            return this.do("context/update", {
                contextId: item.contextId,
                params: {
                    isBlock: !item.isBlock,
                    is1Task: false
                }
            }).then(() => {
                if (this.context.item?.contextId === item.contextId) {
                    // we are updating current context
                    if (willBeBlock) {
                        const redirect = routeForContextFirstActiveTask();
                        if ( redirect ) {
                            if ( redirect.params.taskAuth !== this.task.item?.auth ) {
                                //small pause for animation
                                setTimeout(() => {
                                    this.$router.push(redirect)
                                }, 300);
                            }
                        }
                    }
                }
            });
        },
        isTaskDone(item) {
            return isTaskDone(item);
        },
        /*is1TaskInactive(item) {
            return is1TaskInactive(item);
        },*/
        isContextTaskInactive(context, item) {
            return isContextTaskInactive(context, item);
        },
        isTaskDelayed(item) {
            return isTaskDelayed(item);
        },
        isTaskInactive(item) {
            return isTaskInactive(item);
        },
        isTaskTemplateActive(item) {
            return isTaskTemplateActive(item);
        },
        isTaskOverdue(item) {
            return isTaskOverdue(item);
        },
        isTaskOfMyAttention(item) {
            return isTaskOfMyAttention(item);
        },
        countContextInactiveTasks(context) {
            return countContextInactiveTasks(context);
        },
        countContextActiveTasks(context) {
            return countContextActiveTasks(context);
        },

        hasFullAccessToTask(item) {
            if (!item) return false;
            return !!this.groups?.find(el => el.id === item.groupId);
        },
        delayedDateText(item) {
            if (!item) return false;
            let text = null;
            if (item.delayedTill) {
                let hm = iso2HHMM(item.delayedTill);
                if (hm === "00:00") hm = "";
                else hm = " " + hm;
                text = iso2DDMMYYYY(item.delayedTill) + hm;
            } else if (item.isDelayed) text = "Отложена";
            return text;
        },
        nickOf(user) {
            return nick(user);
        },
        showDialogTaskDelay(item) {
            return this.$store.dispatch("task/dialogDelay", {
                item,
            });
        },
        /*sortTasks() {
            //if (this.isRouteSearch) return;

            return this.do('context/sortTasksByPrio', {
                contextId: this.context.item?.contextId,
                tab: this.context.tab,
            });
        },*/
        groupOfTask(task) {
            return groupOfTask(task);
        },
        topicOfTask(task) {
            return topicOfTask(task);
        },
        tagsOfTask(task) {
            return tagsOfTask(task);
        },
        contextsOfTask(task) {
            return contextsOfTask(task);
        },
        routeOfContext(context) {
            let query = undefined;
            const rootId = rootContextId(context);
            let rootPick;
            if (rootId) rootPick = this.picks.find(el => el.contextId === rootId);
            if (context.isPick || rootPick) {
                const c = rootPick || context;
                if (contextHasDelayedTill(c)) query = {tab: TABS.DELAYED};
                //console.log("PICK", c.conds, query);
            }
            return {name: "context", params: {contextId: context.contextId}, query};
        },
        routeOfContextTask(context, task) {
            return {name: "context-task", params: {context: context.contextId, taskAuth: task.auth}};
        },
        colorByCss(css) {
            if (!css) return null;
            return Config.COLORS.find(el => el.css === css);
        },
        colorOfTag(tag) {
            if (!tag.color) return null;
            const color = this.colorByCss(tag.color);
            return color?.color;
        },
    }
}