import Vue from 'vue';
import Config from '@/config';

const mutations = {
    msgCreate: (state, payload) => {
        console.log("MUTATION msg/create", payload);
        if (!payload.task?.auth) return;

        // after creation - refresh msg list
        payload.mutation = "msgAppend";
        //payload.action = "task/msgs";
        payload.broadcast = {
            entity: "task",
            auth: payload.task?.auth,
        };
    },
    msgAppend: (state, payload) => {
        console.log("MUTATION msg/append", payload);

        let taskId;
        if ( payload.msgAppend?.msg ) {
            // mutation with append only
            taskId = payload.msgAppend.msg.todoId;
            if (state.page.task.item?.id === taskId) {
                state.page.msgs.items.push(payload.msgAppend.msg);
            }
        }
        else if ( payload?.msg?.item.todoId ) {
            // todo this should not be done with task - use first type

            // mutation with task
            taskId = payload?.msg?.item.todoId;
            // 0. currently edited task is updated in setState automatically

            // 1. refresh msgs of current task
            // are we in needed task?
            //console.log("taskId", taskId);
            //console.log("state.page.task.item.id", state.page.task.item?.id);
            if (state.page.task.item?.id === taskId) {
                state.page.msgs.items = payload.msgs.items;
            }

            // 3. update task in current context
            if ( payload.task?.item ) {
                const items = state.page.tasks.items;
                if (items?.length) {
                    const update = (item) => {
                        Object.keys(payload.task?.item).forEach(key => {
                            Vue.set(item, key, payload.task?.item[key]);
                        })
                    }

                    const item = items.find(el => el.id === payload.task.item.id);
                    if (item) update(item);
                }
            }
        }
    },

    /**
     * This does not broadcast.
     * @param state
     * @param payload
     */
    msgSetUploads: (state, payload) => {
        console.log("MUTATION msg/setUploads", payload);
        if (!payload) return;

        Vue.set(state.page.msg, "uploads",  payload);
    },
};

export default mutations;
