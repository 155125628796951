import Config from '@/config';
import {customAlphabet} from 'nanoid';
import storage from "@/utils/storage";
import session from "./session.js";
import page from "./page.js";
import {obtainClientAuth} from "../utils/auth";

let appId = sessionStorage.getItem(Config.STORAGE_APP_ID);
if ( !appId ) {
    const nanoid = customAlphabet(Config.CLIENT_ID_ALPHABET, Config.CLIENT_ID_LENGTH);
    appId = nanoid();
    storage.set(Config.STORAGE_APP_ID, appId);
}
let clientAuth = obtainClientAuth();

let state = {
    appId,
    clientAuth, // browser id + browser tab id

    // данные из БЕ - переустанавливаются при каждом fetchPage
    response: {},	// объект ответа от БЕ в чистом виде
    //common: {},			// общие для всех страниц
    lastError: null,    // объект с последней ошибкой из БЕ

    session, // сессия, пользователь
    page,    // контент для вывода страницы

    // данные вебсокета
    wsRequests: [],
    isWSConnected: false,
    isWSReconnecting: false,
    isWSReconnected: false,
    isLoading: false,
    isRoutingPending: false,
    routingTo: null,
    lastMutations: [],

    // group cache
    sessionRouteBack: null,
    sessionBtns: [],

    navPath: [],
    search: null,
    isSearching: false,
    withSearchTags: false,
    searchTags: [],
    withSoonDialog: false,

    // refresh counters
    refreshCounter: 0,
    shouldUpdateTopics: 0,
    shouldUpdateTodos: 0,
    shouldScrollDownMsgs: 0,

    todoContext: {
        siblings: null
    },

    // реактивные размеры экрана
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
};

export default state;
