import Config from "@/config";
import Vue from "vue";
import {SORT_TYPES} from "@/utils/task";
import {flatArray} from "@/utils/utils";

export const PRESETS = {
    MY: "my",
    RECENT: "recent",
};

export const pickIsSearch = (pick) => {
    if (!pick) return null;
    if (!pick.isPick) return null;
    const searchValue = pickSingleValue(pick, "search");
    return !!searchValue;
}

export const pickSingleValue = (pick, prop) => {
    if (!pick) return null;
    if (!pick.isPick) return null;
    //console.log("pickSingleValue", pick);
    if (!pick.conds) return null;
    //if (pick.conds.length > 1) return null;
    if (pick.conds[0].length > 1) return null;
    if (pick.conds[0][0].key === prop) return pick.conds[0][0].value;
    return null;
}
export const pickSingleIntValue = (pick, prop) => {
    const v = pickSingleValue(pick, prop);
    if (!v) return null;
    return Number(v);
}
export const jointContexts = (area) => {
    return [...(flatArray(area.picks)||[]),...(flatArray(area.userPicks)||[]),...(area.groups||[]), ...(area.topics||[]), ...(area.tags||[]), ...(area.users||[])];
}
export const obtainPickSingleParent = (pick, area) => {
    let group, topic, user;
    const contextId = pickSingleValue(pick, "contextId");
    if ( contextId ) {
        const contexts = jointContexts(area);
        const context = contexts.find(el=>el.contextId === contextId);
        if ( context?.isGroup ) group = context;
        else if ( context?.isTopic ) topic = context;
        else if ( context?.isUser ) user = context;
    }
    else {
        const groupId = pickSingleIntValue(pick, "groupId");
        if (groupId) group = area.groups?.find(el => el.id === groupId);
        else {
            const topicId = pickSingleIntValue(pick, "topicId");
            if (topicId) topic = area.topics?.find(el => el.id === topicId);
            else {
                const userId = pickSingleIntValue(pick, "userId");
                if (userId) user = area.users?.find(el => el.id === userId);
            }
        }
    }
    return {
        group, topic, user
    }
}