import router from "@/router";
import headful from "headful";
import Config from "@/config";
import storage from "@/utils/storage";
import {end} from "@/utils/string";
import {
    contextFirstActiveTask,
    routeForContextFirstActiveTask, TABS
} from "@/utils/task";
import Vue from "vue";
import {pickIsSearch} from "@/utils/pick";

const mutations = {
    uiToggleMobileGroups: (state, payload) => {
        state.ui.withMobileGroups = !state.ui.withMobileGroups;
    },
    uiSetWithMobileGroups: (state, payload) => {
        state.ui.withMobileGroups = payload;
    },
    uiToggleUserListMode: (state, payload) => {
        mutations.uiSetIsUserListMode(state, !state.ui.isUserListMode);
    },
    uiSetIsUserListMode: (state, payload) => {
        console.log("uiSetIsUserListMode", payload);
        state.ui.isUserListMode = !!payload;
        storage.set(Config.STORAGE_USER_LIST_MODE, state.ui.isUserListMode);
    },
    uiExtendMsgEditor: (state, payload) => {
        state.ui.isMsgEditorExpanded = payload;
    },
    uiExpandTaskEditor: (state, payload) => {
        state.ui.isTaskEditorExpanded = payload;
    },
    uiSetSearch: (state, payload) => {
        //console.log("uiSetSearch", payload);
        storage.set(Config.STORAGE_GROUP_SEARCH, state.ui.search.value);
        state.ui.search.value = payload;
    },
    uiSetIsSearching: (state, payload) => {
        if (payload) {
            storage.set(Config.STORAGE_GROUP_SEARCH, state.ui.search.value);
            //state.ui.search.value = payload;
            state.ui.search.isSearching = true;
        } else state.ui.search.isSearching = false;
    },
    uiSetPendingContext: (state, payload) => {
        state.ui.pending.context = payload;
    },
    uiSetPendingTab: (state, payload) => {
        state.ui.pending.tab = payload;
    },
    /*uiSetShouldSortTasks: (state, payload) => {
        state.ui.shouldSortTasks = payload;
    },*/
    uiMenu: (state, payload) => {
        console.log("ITEM MENU MUTATION!", payload);

        if (!payload.menu) return;
        if (!payload.params) return;

        const menu = payload.menu;

        let timeout = 0;
        if (menu.isVisible) {
            menu.isVisible = false;
            timeout = 150;

            if (menu.item === payload.params.item) {
                setTimeout(() => {
                    menu.item = null;
                }, timeout);
                return;
            }
        }

        setTimeout(() => {
            Object.keys(menu).forEach(key => menu[key] = null);
            menu.isVisible = true;
            menu.callback = payload.params.callback;
            //menu.item = payload.params.item;

            menu.x = payload.params.event?.clientX;
            menu.y = payload.params.event?.clientY + 16;

            //console.log("PARAMS!!", payload.params);
            Object.keys(payload.params).forEach(key => menu[key] = payload.params[key]);

        }, timeout);
    },
    uiHide: (state, payload) => {
        console.log("HIDE!", payload);

        const ui = state.ui;
        if (ui[payload]?.isVisible) ui[payload].isVisible = false;
    },
    uiSetContext1Task: (state, payload) => {
        console.log("uiContextMode", payload);

        const groupAuth = payload.groupAuth;
        const topicAuth = payload.topicAuth;
        const tagAuth = payload.tagAuth;

        let context;
        if (tagAuth) {
            context = state.session.area.tags?.find(el => el.auth === tagAuth);
        } else if (topicAuth) {
            context = state.session.area.topics?.find(el => el.auth === topicAuth);
        } else if (groupAuth) {
            context = state.session.area.groups?.find(el => el.auth === groupAuth);
        }
        if (!context) return;

        state.ui.isContext1TaskMode = payload?.isOn;
    },

    /**
     * This mutation is called BEFORE routing.
     * Clear needed ui element states here.
     * @param state
     * @param payload
     */
    uiClearPageBeforeRouting: (state, {to, from}) => {
        console.log("TO", to, "FROM", from);

        if ( !to.params?.apiless ) state.lastError = null;

        //const context = state.page.context.item;
        //const isNowSearchContext = pickIsSearch(context);
        //console.log("isNowSearchContext", isNowSearchContext, to.params.isSearch);

        if (
            !(to.params?.contextId && from.params?.contextId && to.params?.contextId === from.params.contextId)
            //&& !(isNowSearchContext && to.params?.isSearch)
        ) {
            //console.log("ui clear context");

            state.page.tasks.isInited = false;
            state.page.task.isInited = false;
            //state.page.context.tab = TABS.ACTUAL;
            //state.ui.shouldSortTasks = true;
        }
        //else if (to.params?.taskAuth && from.params?.taskAuth && to.params?.taskAuth !== from.params.taskAuth) {
        else if (to.params?.taskAuth) {
            //console.log("ui clear task");
            state.page.task.isInited = false;
            state.ui.isMsgEditorExpanded = false;
            state.ui.isTaskEditorExpanded = false;

            mutations.uiClearMsg(state);
        }

        /*if (to.name !== "search" && to.name !== "search-task") {
            //this.commit("uiClearTask");
            state.ui.search.value = null;
        }*/
    },
    /**
     * This mutation is called AFTER routing.
     * Clear needed ui element states here.
     * @param state
     * @param payload
     */
    uiClearPageAfterRouting: (state, {to, from}) => {
        //console.log("AFTER ROUTING");
        state.ui.withMobileGroups = false;
        //state.ui.search.value = null;
        state.ui.search.isSearching = false;

        // clear all pending items
        // small pause to make things smoother
        setTimeout(() => {
            state.ui.pending.context = null;
            state.ui.pending.tab = null;
        }, 150);
    },
    uiClearMsg: (state, payload) => {
        state.page.msg.item.id = null;
        state.page.msg.item.auth = null;
        state.page.msg.item.text = null;
        state.page.msg.item.action = null;
        //state.page.msg.uploads = null;
        Vue.set(state.page.msg, "uploads", null);
    },

    uiSetNavPath: (state, payload) => {
        state.ui.navPath = payload;
    },
    uiSetTitle: (state, source) => {
        //console.log("uiSetTitle", source);
        let title = Config.DEFAULT_TITLE;
        if (typeof source === "string") {
            title = source;
        } else if (typeof source === "object") {
            if (typeof source.to?.meta?.title === "function") {
                title = source.to.meta.title();
            } else if (typeof source.to?.meta?.title === "string") {
                title = source.to.meta.title;
            }
        }

        headful({
            title,
        });
    },

    uiDialogFilesViewer: (state, payload) => {
        if (!payload?.files?.length) return;

        return new Promise((resolve, reject) => {
            console.log("dialogFilesViewer", payload);
            state.ui.dialogFilesViewer.files = payload.files;
            state.ui.dialogFilesViewer.index = payload.index || 0;
            //state.ui.dialogFilesViewer.callback = payload.callback;
            state.ui.dialogFilesViewer.isVisible = true;
        });
    },

    uiSetActorOfGroupTaskSort: (state, payload) => {
        mutations.uiSetActor(state, {
            mutation: state.ui.mutations.groupTaskSort,
            ...payload
        });
    },
    uiSetActorOfTask: (state, payload) => {
        mutations.uiSetActor(state, {
            mutation: state.ui.mutations.task,
            ...payload
        });
    },
    uiSetActorOfGroup: (state, payload) => {
        mutations.uiSetActor(state, {
            mutation: state.ui.mutations.group,
            ...payload
        });
    },
    uiSetActor: (state, payload) => {
        //console.log("SETTING ACTOR", payload);
        clearTimeout(payload.mutation.timeout);
        payload.mutation.userAuth = payload.userAuth;
        payload.mutation.subjectAuth = payload.subjectAuth;
        payload.mutation.icon = payload.icon;
        payload.mutation.timeout = setTimeout(() => {
            payload.mutation.userAuth = null;
        }, payload.timeout || 600);
    },

    uiValidateRoute: (state, {to, from}) => {
        // should no open together context and task that does not belong to it
        let redirect = null;
        const myRoute = {name: "my"};
        const picks = state.session.area.picks;
        const topics = state.session.area.topics;
        const groups = state.session.area.groups;
        const users = state.session.area.users;
        const context = state.page.context;
        const tasks = state.page.tasks.items;
        const task = state.page.task.item;
        const isAnon = state.session.isAnon;

        //console.log("context topic", context.topic);
        if (to.name === "home") {
            const context = picks.find(el => el.preset === "my");
            if (context) {
                redirect = {
                    name: 'context',
                    params: {contextId: context.id}
                };
            }
            /*if ( context.topic?.is1Task ) {
                const countActiveTasks = countContextActiveTasks();
                if ( countActiveTasks) {
                    const task = tasks && tasks[0];
                    if (task) {
                        redirect = {
                            name: 'home-task',
                            params: {contextId: contextId, taskAuth: task.auth}
                        };
                    }
                    else redirect = {
                        name: 'home-done',
                        params: {}
                    };
                }
                else redirect = {
                    name: 'home-done',
                    params: {}
                };
            }*/
        } else if (to.name === "home-task") {
            if (!tasks?.find(el => el.id === task.id)) {
                if (isAnon) {
                    redirect = {
                        name: "home-task",
                        params: {taskAuth: task.auth}
                    }
                    //redirect = myRoute;
                } else if (task.topicId) {
                    // first we try topic
                    const topic = topics?.find(el => el.id === task.topicId);
                    if (topic) redirect = {
                        name: "topic-task",
                        params: {topicAuth: topic.auth, taskAuth: task.auth}
                    }
                    else redirect = myRoute;
                } else {
                    const group = groups?.find(el => el.id === task.groupId);
                    if (group) redirect = {
                        name: "group-task",
                        params: {groupAuth: group.auth, taskAuth: task.auth}
                    }
                    // now we try assignee
                    else {
                        const assignee = users?.find(el => el.id === task.userId);
                        if (assignee) redirect = {
                            name: "assignee-task",
                            params: {assigneeAuth: assignee.auth, taskAuth: task.auth}
                        }
                        else redirect = myRoute;
                    }
                }
            }
        } else if (isAnon) {
            redirect = {
                name: "auth"
            }
        } else if (to.name === "context") {
            if (context.item?.is1Task || context.item?.isBlock) {
                redirect = routeForContextFirstActiveTask();
            }
        } else if (to.name === "context-task") {
            if (context.item?.isBlock) {
                const firstTask = contextFirstActiveTask();
                if (firstTask.auth !== task?.auth) {
                    redirect = routeForContextFirstActiveTask();
                }
            }
        }
        // invalid topic/group
        /*if (context.topic?.id && task.topicId !== context.topic?.id) redirect = {
            name: "topic-task",
            params: {topicAuth: context.topic.auth, taskAuth: task.auth}
        };
        else if (context.group?.id && task.groupId !== context.group?.id) redirect = {
            name: "group-task",
            params: {groupAuth: context.group.auth, taskAuth: task.auth}
        };*/

        if (redirect) {
            console.log("REDIRECT ON INVALID ROUTE", to.name, redirect);
            router.push(redirect);
            return false;
        }
        console.log("VALID ROUTE");
        return true;
    },
    uiRouteToTab: (state, payload) => {
        // skip routing if we are here
        if (state.page.context.tab === payload) return;

        let query = {...router.currentRoute.query, tab: payload};
        state.page.context.tab = payload;
        state.ui.pending.tab = payload;
        //this.$store.commit("uiSetPendingTab", tabItem.id);

        //console.log("routing... for TAB", payload, router.currentRoute.params, query);
        router.push({
            name: router.currentRoute.name,
            params: {...router.currentRoute.params, skipContext: false, apiless: false},	// force api request
            query
        });
    },
    uiSetTab: (state, payload) => {
        state.page.context.tab = payload;
    },
    uiRedirect: (state, payload) => {
        console.log("uiRedirect", payload);

    },
    uiError: (state, payload) => {
        //console.log("uiError", payload);

        const error = state.ui.error;

        error.isVisible = true;
        error.message = payload.message;
        error.code = payload.code;
    },
};

export default mutations;
